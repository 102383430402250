import {EUserRole, useUserStore} from '@/app/shared/state/UserDataModule';
import { useCodeListStore } from '@/app/shared/state/CodeListModule';
import { mriInvoices } from '@/app/mri/mri-routes';
import { settings } from '@/app/settings/settings-routes';
import { klipIndex } from '@/router/router';
import { Prepaid } from '@/app/prepaid/prepaid-routes';
import { PaymentTypes, LogEventLevel, LogImpact, LogEntry } from '@/api/klip-api.proxy';
import {computed, defineComponent, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/plugins/routes';
import {useSignalrHub} from '@/plugins/signalr';

export default defineComponent({
    name: 'KlProfileDropdown',
    props: {},
    setup(props) {

        const router = useRouter();

        const isOpen = ref<boolean>(false);
        const toggleFocus = ref<boolean>(false);
        const prepaidSaldo = ref<number>(null);
        const isFinancialServiceAvailable = ref<boolean>(true);

        const isOnlyPdaManager = computed<boolean>(() => {
            if (user.value.roles.length > 1) {
                return false;
            }
           return useUserStore().hasRole([EUserRole.pda_manager]);
        });

        const user = computed(() => {
            return useUserStore().user;
        });

        const userDataScopes = computed(() => {
            return useUserStore().scopes;
        });

        const userDataScope = computed(() => {
            return useUserStore().scope;
        });

        const financialServiceAvailable = computed(() => {
            return isFinancialServiceAvailable.value;
        });

        const organisationName = computed(() => {
            let name = user.value.organisation;
            if (useUserStore().hasRole([EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader]) &&
                user.value.displayName
            ) {
                name = user.value.displayName;
            }
            return name;
        });

        const routeMriInvoices = computed(() => {
            return mriInvoices;
        });

        const routeSettings = computed(() => {
            return settings;
        });

        const routeKlipIndex = computed(() => {
            return klipIndex;
        });

        const routePrepaid = computed(() => {
            return Prepaid;
        });

        const userScope = computed(() => {
            if(useUserStore().scopes.length === 0 && useUserStore().hasRole([EUserRole.org_manager])) {
                return {
                    key: 'org',
                    abbr: 'ORG',
                    label: 'Organisatie Beheerder'
                }
            }
            return useUserStore().scopes.find((scope) => useUserStore().scope === scope.key);
        });

        const userHasMultipleRoles = computed(() => {
            return Object.keys(useUserStore().scopes).length > 1;
        });

        const showRolepicker = computed(() => {
            return userHasMultipleRoles.value;
        });

        const rolePickerItems = computed(() => {
            return user.value.roles.map((role) => {
                return {
                    key: role,
                    label: useCodeListStore().userRoleCodes(role),
                };
            });
        });

        const showSettings = computed(() => {
            //hide settings if the user has only the map request reader role.
            if (user.value.roles.length > 1) {
                return true;
            }
            return !useUserStore().hasRole([EUserRole.mrr]);
        });

        const applyMinWidth = computed(() => {
            return !showRolepicker.value;
        });

        const showBalance = computed(() => {
            return useUserStore().paymentType !== PaymentTypes.Invoice && !useUserStore().user.isCitizen && useUserStore().scope === 'mri' && useUserStore().hasRole([EUserRole.mri]);
        });

        const hasPrepaidSaldo = computed(() => {
            return prepaidSaldo.value != null;
        });


        const close = () => {
            isOpen.value = false;
        }

        const toggle = () => {
            isOpen.value = !isOpen.value;
            if (isOpen.value && showBalance.value) {
                useKlipApiProxy().payment_GetPrepaidAmount()
                    .then((response) => {
                        isFinancialServiceAvailable.value = response.result.financialServiceAvailable;
                        prepaidSaldo.value = response.result.amount;
                    });
            }
        }

        const switchScope = (scope: string) => {
            useUserStore().setScope(scope);
            close();
            router.push('/' + scope);
        }

        return {
            isOpen,
            toggleFocus,
            prepaidSaldo,
            isOnlyPdaManager,
            user,
            userDataScopes,
            userDataScope,
            organisationName,
            routeMriInvoices,
            routeSettings,
            routeKlipIndex,
            routePrepaid,
            userScope,
            userHasMultipleRoles,
            showRolepicker,
            rolePickerItems,
            showSettings,
            applyMinWidth,
            showBalance,
            hasPrepaidSaldo,
            financialServiceAvailable,
            close,
            toggle,
            switchScope
        }
    }
})
