import { KlRouteConfig } from "@/router/router-vue";
import {EUserRole} from '@/app/shared/state/UserDataModule';

export const AdminHome: KlRouteConfig = {
    path: '/admin',
    name: 'adminHome',
    component: () => import('./index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin, EUserRole.helpdesk] },
    },
};

export const AdminServiceDetail: KlRouteConfig = {
    path: '/admin/service/:name',
    name: 'adminServiceDetail',
    component: () => import('./dashboard/service-detail/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin, EUserRole.helpdesk] },
    },
};

export const AdminEvents: KlRouteConfig = {
    path: '/admin/events',
    name: 'adminEvents',
    component: () => import('./events/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    },
};

export const AdminHangingImklValidations: KlRouteConfig = {
    path: '/admin/hangingimklvalidations',
    name: 'adminHangingImklValidations',
    component: () => import('./dashboard/hanging-imkl-validations/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminFailedPmklPackages: KlRouteConfig = {
    path: '/admin/failedpmklpackages',
    name: 'adminFailedPmklPackages',
    component: () => import('./dashboard/failed-pmkl-packages/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminHangingPendingRequests: KlRouteConfig = {
    path: '/admin/hangingpendingrequests',
    name: 'adminHangingPendingRequests',
    component: () => import('./dashboard/hanging-pending-requests/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminHangingPendingPmkl2Assets: KlRouteConfig = {
    path: '/admin/hangingpmkl2assets',
    name: 'adminHangingPmkl2Assets',
    component: () => import('./dashboard/hanging-pending-pmkl-2-assets/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminFailedMissingKlim: KlRouteConfig = {
    path: '/admin/failedmissingklim',
    name: 'adminFailedMissingKlim',
    component: () => import('./dashboard/failed-missing-klim/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminHangingMapRequests: KlRouteConfig = {
    path: '/admin/hangingmaprequests',
    name: 'adminHangingMapRequests',
    component: () => import('./dashboard/hanging-map-requests/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
}

export const AdminOverdueMapRequests: KlRouteConfig = {
    path: '/admin/overduemaprequests',
    name: 'adminOverdueMapRequests',
    component: () => import('./dashboard/overdue-maprequests/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
}

export const AdminMapRequestRespondersIncorrectState: KlRouteConfig = {
    path: '/admin/maprequestrespondersincorrectstate',
    name: 'adminMapRequestRespondersIncorrectState',
    component: () => import('./dashboard/maprequest-responders-incorrect-state/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
}

export const AdminMapRequestWithFinancialAnomalies: KlRouteConfig = {
    path: '/admin/maprequestwithfinancialanomalies',
    name: 'adminMapRequestWithFinancialAnomalies',
    component: () => import('./dashboard/maprequest-with-financial-anomalies/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
}

export const AdminUnprocessedShoppingCarts: KlRouteConfig = {
    path: '/admin/unprocessedshoppingcarts',
    name: 'adminUnprocessedShoppingCarts',
    component: () => import('./dashboard/unprocessed-shopping-carts/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
};

export const AdminMapRequestOverview: KlRouteConfig = {
    path: '/admin/maprequest',
    name: 'adminMapRequest',
    component: () => import('./maprequest/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminMapRequestDetail: KlRouteConfig = {
    path: '/admin/maprequest/:id',
    name: 'adminMapRequestDetail',
    component: () => import('./maprequest/map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminArchivedMapRequestDetail: KlRouteConfig = {
    path: '/admin/maprequest/:id/archived',
    name: 'adminArchivedMriDetail',
    component: () => import('./maprequest/archived-map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] }
    }
}

export const AdminMapRequestDetailAsMri: KlRouteConfig = {
    path: '/admin/maprequest/:id/mri',
    name: 'adminMapRequestDetailAsMri',
    component: () => import('../mri/map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminMapRequestDetailAsUna: KlRouteConfig = {
    path: '/admin/maprequest/:id/una/:zoneId/:archived',
    name: 'adminMapRequestDetailAsUna',
    component: () => import('../una/map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminUser: KlRouteConfig = {
    path: '/admin/user',
    name: 'adminUser',
    component: () => import('./user/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminUserDetail: KlRouteConfig = {
    path: '/admin/user/:userId/:organisationId?',
    name: 'adminUserDetail',
    component: () => import('./user/user.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminOrganisation: KlRouteConfig = {
    path: '/admin/organisation',
    name: 'adminOrganisation',
    component: () => import('./organisation/pages/overview/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminOrganisationDetail: KlRouteConfig = {
    path: '/admin/organisation/:organisationId',
    name: 'adminOrganisationDetail',
    component: () => import('./organisation/pages/detail/organisation-detail.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminOrganisationDetailPdaZone: KlRouteConfig = {
    path: '/admin/organisation/:organisationId/pdazone/:zoneId',
    props: true,
    name: 'adminOrganisationPdaZone',
    component: () => import('./organisation/pages/pda-zone-detail/pda-zone-detail.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminPdaZoneMapRequestDetail: KlRouteConfig = {
    path: '/admin/organisation/:organisationId/pdazone/:zoneId/maprequest/:id',
    props: true,
    name: 'adminPdaZoneMapRequest',
    component: () => import('../pda/map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminSubOrganisationDetailUnaZone: KlRouteConfig = {
    path: '/admin/organisation/suborganisation/:organisationId/unazone/:zoneId',
    props: true,
    name: 'adminSubOrganisationDetailUnaZone',
    component: () => import('./organisation/pages/sub-organisation-una-zone/sub-organisation-una-zone.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminSubOrganisationDetail: KlRouteConfig = {
    path: '/admin/organisation/suborganisation/:organisationId',
    name: 'adminSubOrganisationDetail',
    component: () => import('./organisation/pages/sub-organisation/sub-organisation.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminSubOrganisationDetailUnaKlimZone: KlRouteConfig = {
    path: '/admin/organisation/suborganisation/:organisationId/unazoneklim/:zoneId',
    props: true,
    name: 'adminSubOrganisationDetailUnaKlimZone',
    component: () => import('./organisation/pages/sub-organisation-una-zone/sub-organisation-una-zone.vue'),
    meta: {
        authorize: { roles: [EUserRole.helpdesk, EUserRole.admin] },
    },
};

export const AdminInvoice: KlRouteConfig = {
    path: '/admin/invoice',
    name: 'adminInvoice',
    component: () => import('./invoice/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    },
};

export const AdminInvoiceGroups: KlRouteConfig = {
    path: '/admin/invoice#invoiceGroups',
    name: 'adminInvoiceGroups',
    component: () => import('./invoice/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    },
};

export const AdminInvoiceGroup: KlRouteConfig = {
    path: '/admin/invoice/invoicegroup/:parentId',
    name: 'adminInvoiceGroup',
    component: () => import('./invoice/pages/detail/detail.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    },
};

export const AdminNewInvoiceGroup: KlRouteConfig = {
    path: '/admin/invoice/new/invoicegroup',
    name: 'adminNewInvoiceGroup',
    component: () => import('./invoice/pages/create/create.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    }
}

export const AdminSettings: KlRouteConfig = {
    path: '/admin/settings',
    name: 'adminSettings',
    component: () => import('./settings/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.admin] },
    },
};

export default [
    AdminHome,
    AdminServiceDetail,
    AdminEvents,
    AdminHangingImklValidations,
    AdminHangingMapRequests,
    AdminOverdueMapRequests,
    AdminMapRequestWithFinancialAnomalies,
    AdminFailedPmklPackages,
    AdminFailedMissingKlim,
    AdminHangingPendingRequests,
    AdminHangingPendingPmkl2Assets,
    AdminMapRequestRespondersIncorrectState,
    AdminUnprocessedShoppingCarts,
    AdminMapRequestOverview,
    AdminMapRequestDetail,
    AdminArchivedMapRequestDetail,
    AdminMapRequestDetailAsMri,
    AdminMapRequestDetailAsUna,
    AdminUser,
    AdminUserDetail,
    AdminOrganisation,
    AdminOrganisationDetail,
    AdminOrganisationDetailPdaZone,
    AdminSubOrganisationDetail,
    AdminSubOrganisationDetailUnaZone,
    AdminSubOrganisationDetailUnaKlimZone,
    AdminInvoice,
    AdminInvoiceGroups,
    AdminInvoiceGroup,
    AdminNewInvoiceGroup,
    AdminSettings,
    AdminPdaZoneMapRequestDetail] as KlRouteConfig[];
