import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';
import {EUserRole} from '@/app/shared/state/UserDataModule';

export const UnaIndex: KlRouteConfig = {
    path: '/una',
    name: 'una',
    component: () => import(/* webpackChunkName: "una" */ './index.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const UnaMapRequest: KlRouteConfig = {
    path: '/una/maprequest/:id/:zoneId/:archived',
    name: 'unamaprequest',
    component: () => import(/* webpackChunkName: "una" */ './map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_operator, EUserRole.una_reader] },
        breadcrumb: { title: 'Planaanvraag', parent: UnaIndex },
    },
};

export const UnaViewer: KlRouteConfig = {
    path: '/una/viewer/:mapRequestId/:zoneId',
    name: 'unaviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        authorize: { roles: [EUserRole.una_operator, EUserRole.una_reader, EUserRole.helpdesk, EUserRole.admin] },
        breadcrumb: { title: 'Leidingviewer', parent: UnaMapRequest },
    },
};

export default [
    UnaIndex,
    UnaMapRequest,
    UnaViewer,
];
