import {IMapRequestArchive, useUnaStore } from '../../../../una-store';
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlUnaMaprequestArchiveDownload',
    props: {
        maprequestId: {
            type: String, required: true,
        },
        zoneId: {
            type: String, required: true,
        },
        downloadUrl: {
            type: String, required: true,
        },
    },
    setup(props) {

        // @Getter(UnaStore.store.getters.mapRequestArchives, { namespace: UnaStore.store.namespace })
        const mapRequestArchives = computed((): IMapRequestArchive[] => {
            return useUnaStore().mapRequestArchives;
        });

        const fileUrl = computed(() => {
            return props.downloadUrl;
        });

        const downloadButtonClasses = computed(() => {
            return ['vl-button'];
        });

        const status = computed(() => {
            return storedArchive.value ? storedArchive.value.status : null;
        });

        const storedArchive = computed(() => {
            const mapRequestArchive = mapRequestArchives.value ? mapRequestArchives.value.filter((item) => item.id === props.maprequestId && item.zoneId === props.zoneId) : [];
            return mapRequestArchive.length ? mapRequestArchive[0] : null;
        });

        const loading = computed(() => {
            return status.value === 'loading';
        });

        const error = computed(() => {
            return status.value === 'error';
        });

        const ready = computed(() => {
            return status.value === 'ready';
        });

        return {
            fileUrl,
            downloadButtonClasses,
            loading,
            error,
            ready
        };
    }
})
