import {KlRouteConfig} from '@/router/router-vue';
import {useUserStore} from '@/app/shared/state/UserDataModule';

export function canCurrentUserAccessRoute(route: KlRouteConfig) {
    if (route.meta && route.meta.authorize && route.meta.authorize.roles) {
        return useUserStore().hasRole(route.meta.authorize.roles);
    }

    return true;
}
