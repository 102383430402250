import { klipIndex } from '@/router/router';
import {EUserRole, useUserStore} from '@/app/shared/state/UserDataModule';
import { PaymentTypes } from '@/api/klip-api.proxy';
import {KlRouteConfig} from '@/router/router-vue';

export const Prepaid: KlRouteConfig = {
  path: '/prepaid',
  name: 'prepaid',
  component: () => import('./prepaid.vue'),
  meta: {
    authorize: { roles: [EUserRole.mri, EUserRole.mrr] }, // TODO: Check roles
    breadcrumb: { title: 'Prepaid saldo', parent: klipIndex },
  },
  beforeEnter: (to, from, next) => {
    if (useUserStore().user.paymentType !== PaymentTypes.Invoice && !useUserStore().user.isCitizen ) {
      next();
    } else {
      next({ name: 'mri'});
    }
  },
};

export default [
  Prepaid,
];
