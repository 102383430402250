import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';

export interface IMapRequestArchive {
  id: string;
  status: string;
}

export const useMriStore = defineStore('mri-store', () => {
    const items: Ref<IMapRequestArchive[]> = ref([]);

    // TODO: investigate > never used??
    const MapRequestArchiveStatusMutation = (mapRequestArchive: IMapRequestArchive) => {
        if (items.value.filter((item) => item.id === mapRequestArchive.id).length) {
            items.value = items.value.map((item) => (item.id === mapRequestArchive.id) ? mapRequestArchive : item);
        } else {
            items.value.push(mapRequestArchive);
        }
    }

    const mapRequestArchives = computed((): IMapRequestArchive[] => {
        return items.value;
    });

    return {
        MapRequestArchiveStatusMutation,
        mapRequestArchives,
    }
});
