import { useShoppingCartStore } from './ShoppingCartModule';
import { useUserStore } from '@/app/shared/state/UserDataModule';
import { PaymentTypes } from '@/api/klip-api.proxy';
import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';

export interface IOnlinePaymentData {
    paymentType: string;
    numberOfMapRequests: number;
    hasMapRequestExtras: boolean;
    hasMapRequestsWithImkl: boolean;
    totalPrice: number;
}

export const usePaymentStatusStore_toBeRemoved = defineStore('payment-status-store-toberemoved', () => {

    const hasError: Ref<boolean> = ref(false);
    const hasPrepaidError: Ref<boolean> = ref(false);
    const hasUncertainPrepaidError: Ref<boolean> = ref(false);
    const onlinePayment: Ref<IOnlinePaymentData> = ref(null);
    const mapRequestPrice: Ref<number> = ref(null);
    const prepaidAmount: Ref<number> = ref(null);
    const hasPrepaidPaymentMessage: Ref<boolean> = ref(false);


    const isPaymentMessagesVisible = computed((): boolean => {
        return isShoppingCartPaymentVisible.value || hasError.value || hasOnlinePaymentData.value;
    });

    const hasPaymentError = computed(() => {
        return hasError.value;
    });

    const hasPrepaidPaymentError = computed(() => {
        return hasPrepaidError.value;
    });

    const hasUncertainPrepaidPaymentError = computed(() => {
        return hasUncertainPrepaidError.value;
    });

    const isShoppingCartPaymentVisible = computed((): boolean => {
        return useUserStore().paymentType !== PaymentTypes.Invoice && useShoppingCartStore().isShoppingCartActive && useShoppingCartStore().numberOfItems > 0;
    });

    const numberOfItemsInCart = computed((): number => {
        return useShoppingCartStore().numberOfItems;
    });

    const hasOnlinePaymentData = computed((): boolean => {
        return !!onlinePayment.value;
    });

    const onlinePaymentData = computed((): IOnlinePaymentData => {
        return onlinePayment.value;
    });

    const hasPrepaidAmount = computed(() => {
        return prepaidAmount.value !== null;
    });

    const prepaidAmountData = computed(() => {
        return prepaidAmount.value;
    });

    const hasPrepaidPaymentMessageData = computed(() => {
        return hasPrepaidPaymentMessage.value;
    })



    const setPaymentError = (value: boolean) => {
        hasError.value = value;
    }

    const setPrepaidPaymentError = (value: boolean) => {
        hasPrepaidError.value = value;
    }

    const setHasUncertainPrepaidError = (value: boolean) => {
        hasUncertainPrepaidError.value = value;
    }

    const setOnlinePayment = (onlinePaymentData: IOnlinePaymentData) => {
        onlinePayment.value = onlinePaymentData;
        if (mapRequestPrice.value !== null) {
            onlinePayment.value.totalPrice += mapRequestPrice.value;
        }
    }

    const clearOnlinePayment = () => {
        onlinePayment.value = null;
        mapRequestPrice.value = null;
    }

    const clearPaymentMessages = () => {
        hasError.value = false;
        hasPrepaidError.value = false;
        hasUncertainPrepaidError.value = false;
        onlinePayment.value = null;
        mapRequestPrice.value = null;
    }

    const setMapRequestPrice = (price: number) => {
        mapRequestPrice.value = price;
    }

    const setPrepaidAmount = (amount: number) => {
        prepaidAmount.value = amount;
    }

    const clearPrepaidAmount = () => {
        prepaidAmount.value = null;
    }

    const setPrepaidPaymentMessage = () => {
        hasPrepaidPaymentMessage.value = true;
    }

    const clearPrepaidPaymentMessage = () => {
        hasPrepaidPaymentMessage.value = false;
    }

    return {
        isPaymentMessagesVisible,
        hasPaymentError,
        hasPrepaidPaymentError,
        hasUncertainPrepaidPaymentError,
        isShoppingCartPaymentVisible,
        numberOfItemsInCart,
        hasOnlinePaymentData,
        onlinePaymentData,
        hasPrepaidAmount,
        prepaidAmountData,
        hasPrepaidPaymentMessageData,

        setPaymentError,
        setPrepaidPaymentError,
        setHasUncertainPrepaidError,
        setOnlinePayment,
        clearOnlinePayment,
        clearPaymentMessages,
        setMapRequestPrice,
        setPrepaidAmount,
        clearPrepaidAmount,
        setPrepaidPaymentMessage,
        clearPrepaidPaymentMessage,
    }
});
