import {IMapRequestArchive, useMriStore} from '@/app/mri/mri-store';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';

export default defineComponent({
    name: 'KlMriMaprequestArchiveDownload',
    props: {
        maprequestId: { type: String, required: true },
        downloadUrl: { type: String, required: true },
        showConfirmCheckbox: { type: Boolean, required: false, default: true },
        downloadButtonText: { type: String, required: false, default: 'Download archiefpakket'},
    },
    setup(props) {

        const confirmCheckbox = ref<boolean>();

        const _mapRequestArchives = computed((): IMapRequestArchive[] => useMriStore().mapRequestArchives);


        const fileUrl = computed(() => {
            return !isDownloadButtonDisabled.value ? props.downloadUrl : null;
        });

        const downloadButtonClasses = computed(() => {
            return [
                'vl-button', {
                    'vl-button--disabled': isDownloadButtonDisabled.value,
                }];
        });

        const loading = computed(() => {
            return status.value === 'loading';
        });

        const error = computed(() => {
            return status.value === 'error';
        });

        const ready = computed(() => {
            return status.value === 'ready';
        });

        const status = computed(() => {
            return storedArchive.value ? storedArchive.value.status : null;
        });

        const storedArchive = computed(() => {
            const mapRequestArchive = _mapRequestArchives.value ? _mapRequestArchives.value.filter((item) => item.id === props.maprequestId) : [];
            return mapRequestArchive.length ? mapRequestArchive[0] : null;
        });

        const isDownloadButtonDisabled = computed(() => {
            return props.showConfirmCheckbox && !confirmCheckbox.value;
        });


        watch(
            status,
            (status: string) => {
                confirmCheckbox.value = !!status;
            },
            { immediate: true, deep: false });

        onMounted(() => {
            confirmCheckbox.value = false;
        })

        return {
            confirmCheckbox,
            fileUrl,
            downloadButtonClasses,
            loading,
            error,
            ready,
            status,
            storedArchive,
            isDownloadButtonDisabled,
        }
    }
})
