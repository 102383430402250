<template>
    <div class="sidebar-panel">
        <kl-draw-zone-sidebar-header :title="sidebarTitle" tag-style="h5"/>

        <vl-sidebar-content v-if="canAddKlbZone || canAddKlimZone" class="kl-sidebar__section"
                            ref="klb-actions">
            <vl-action-group class="kl-sidebar__section__actions">
                <vl-button v-if="canAddKlbZone" @click="addNewKlbZone" mod-small mod-block
                           class="kl-sidebar__section__actions__button">Zone toevoegen
                </vl-button>
                <vl-button v-if="canAddKlimZone" @click="addNewKlimZone" mod-small mod-secondary mod-block
                           class="kl-sidebar__section__actions__button">KLIM-zone toevoegen
                </vl-button>
            </vl-action-group>
        </vl-sidebar-content>

        <vl-sidebar-content v-if="showKlbActiveZoneToggle" class="kl-sidebar__section">
            <vl-checkbox v-model="showOnlyActiveZones" @input="toggleShowActive">Toon enkel de actieve zones</vl-checkbox>
        </vl-sidebar-content>

        <vl-sidebar-content v-if="!hasZones" class="kl-sidebar__section">
            Er zijn geen zones gevonden.
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler v-if="!hasZones"/>

        <vl-sidebar-content mod-container v-if="hasZones">
            <vue-custom-scrollbar
                :settings="{ suppressScrollX: true, suppressScrollY: false, wheelPropagation: false }"
                class="kl-sidebar__scroll-area"
            >
                <ul class="kl-sidebar__list">
                    <li class="kl-sidebar__section" v-for="(cz) in notArchivedZones" :key="cz.id">
                        <kl-draw-zone-sidebar-klb-list-item
                            :zone="cz"
                            @highlight-klb-zone="onKlbZoneHover(cz.id)"
                            @edit-klb-zone="onEditKlbZone(cz.id)"
                        />
                    </li>
                </ul>
                <template v-if="hasArchivedZones">
                    <kl-draw-zone-sidebar-header title="Gearchiveerde zones" tag-style="h5"/>
                    <li class="kl-sidebar__section" v-for="(cz) in archivedZones" :key="cz.id">
                        <kl-draw-zone-sidebar-klb-list-item
                            :zone="cz"
                            @highlight-klb-zone="onKlbZoneHover(cz.id)"
                            @edit-klb-zone="onEditKlbZone(cz.id)"
                        />
                    </li>
                </template>
            </vue-custom-scrollbar>
        </vl-sidebar-content>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';
import {IDrawZone} from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import {isEmpty} from 'lodash-es';
import DateUtil from '@/app/shared/helpers/date-util';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import KlDrawZoneSidebarKlbListItem
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-klb-list-item.vue';

export default defineComponent({
    name: 'KlDrawZoneSidebarKlbList',
    emits: ['add-new-klb-zone', 'add-new-klim-zone', 'edit-klb-zone', 'highlight-klb-zone', 'toggle-show-active'],
    components: {
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader,
        KlDrawZoneSidebarKlbListItem,
        vueCustomScrollbar,
    },
    props: {
        zones: {
            type: Array as () => IDrawZone[],
            default: () => [],
        },
        sidebarTitle: { type: String, default: null },
        canAddKlbZone: { type: Boolean, default: false },
        canAddKlimZone: {type: Boolean, default: false },
        // Toggle is only for UI, not for actual filtering.
        // Actual filtering is done in the parent component, because the toggle also triggers a different backend call.
        showKlbActiveZoneToggle: { type: Boolean, default: false, },
        klbActiveZoneToggleValue: { type: Boolean, default: false, },
    },
    setup(props, { emit }) {

        const showOnlyActiveZones = ref(false);

        const notArchivedZones = computed((): IDrawZone[] => {
            return props.zones?.filter((zone: IDrawZone) => !zone.orgZoneInfo?.isArchived);
        });

        const archivedZones = computed((): IDrawZone[] => {
            return props.zones?.filter((zone: IDrawZone) => zone.orgZoneInfo?.isArchived);
        });

        const hasArchivedZones = computed(() => {
            return !isEmpty(archivedZones.value);
        });

        const hasZones = computed(() => {
            return !isEmpty(props.zones);
        });

        const onEditKlbZone = (zoneId: number) => {
            emit('edit-klb-zone', zoneId);
        }

        const onKlbZoneHover = (zoneId: number) => {
            emit('highlight-klb-zone', zoneId);
        }

        const addNewKlbZone = (zoneId: number) => {
            emit('add-new-klb-zone', zoneId);
        }

        const addNewKlimZone = (zoneId: number) => {
            emit('add-new-klim-zone', zoneId);
        }

        const toggleShowActive = (newValue: boolean) => {
            emit('toggle-show-active', newValue);
        }

        watch(
            () => props.klbActiveZoneToggleValue,
            (newValue: boolean) => {
                showOnlyActiveZones.value = props.klbActiveZoneToggleValue
            },
            {immediate: true});

        return {
            showOnlyActiveZones,
            hasZones,
            notArchivedZones,
            archivedZones,
            hasArchivedZones,
            onEditKlbZone,
            onKlbZoneHover,
            addNewKlbZone,
            addNewKlimZone,
            toggleShowActive,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
