import { useUserStore } from "@/app/shared/state/UserDataModule";
import { publicRegistrationRoute } from "@/app/public/public-routes";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {},
  setup() {
    const linkKlip = ref<string>(import.meta.env.VITE_LINKS_KLIP);
    const linkMapRequest = ref<string>(import.meta.env.VITE_LINKS_CREATEMAPREQUEST);
    const linkMapRequestRules = ref<string>(import.meta.env.VITE_LINKS_MAPREQUESTRULES);
    const linkArchiveMapRequest = ref<string>(import.meta.env.VITE_LINKS_ARCHIVE);
    const linkKlipApp = ref<string>(import.meta.env.VITE_LINKS_KLIPAPPURL);
    const linkKlipLegal = ref<string>(import.meta.env.VITE_LINKS_KLIPLEGAL);

    const routePublicRegistrationRoute = computed(() => {
      return `${publicRegistrationRoute.path}#stap1`;
    });

    const linkLogin = computed((): string => {
      return "auth/login";
    });

    onMounted(() => {
        useUserStore().updateIsCheckingAuthentication(false);
    });

    return {
      linkKlip,
      linkMapRequest,
      linkMapRequestRules,
      linkArchiveMapRequest,
      linkKlipApp,
      linkKlipLegal,
      routePublicRegistrationRoute,
      linkLogin,
    };
  },
});
