import { defineComponent, inject, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {KlRouteConfig} from '@/router/router-vue';

export class ProcessCounterData {
    counterType: KlipApi.ProcessCounter;
    counterRoute: KlRouteConfig;
    counterText: string;
}

export default defineComponent({
    props: {
        counter: {
            type: ProcessCounterData,
            default: [],
            required: true
        }
    },
    setup(props) {
        const klipApiProxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const processCounterLoading = ref<boolean>(true);
        const processCounter = ref<number>(0);
        const processCounterText = ref<string>();
        const processCounterRoute = ref<KlRouteConfig>();
        const processCounterLoadingFailed = ref<boolean>(false);
        const processCounterCssClass = (counter: number) => {
            return counter > 0 ? 'alert' : 'success';
        };

        const processCounterIcon = (counter: number) => {
            return counter > 0 ? 'alert-circle' : 'check-circle';
        };

        const counterChanged = async (counter: ProcessCounterData) => {
            const counterResult = await klipApiProxy.processes_ProcessCounter(counter.counterType);
            processCounterText.value = counter.counterText;

            if (counterResult.isSuccessStatusCode && counterResult.result >= 0) {
                processCounter.value = counterResult.result;
                processCounterRoute.value = counter.counterRoute;
            } else {
                processCounterLoadingFailed.value = true;
            }

            processCounterLoading.value = false;
        };

        watch(() => props.counter, counterChanged, { immediate: true, deep: true });

        return {
            processCounterLoadingFailed,
            processCounterLoading,
            processCounterCssClass,
            processCounterText,
            processCounterIcon,
            processCounterRoute,
            processCounter
        };
    }
});
