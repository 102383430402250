import {defineStore} from 'pinia';
import {computed, Ref, ref} from 'vue';

export enum EAlertType {
    Error = 'mod-error',
    Warning = 'mod-warning',
    Success = 'mod-success',
}

export interface IAlertNotification {
    type: EAlertType;
    id: string;
    title: string;
    message: string;
    pageName?: string;
    icon?: 'cross' | 'warning' | 'info-circle';
    closable?: boolean;
    autoClose?: number;
}

export const useAlertStore = defineStore('alert-store', () => {
    const alerts: Ref<IAlertNotification[]> = ref([]);

    const addAlert = (newAlert: IAlertNotification) => {
        if (alerts.value.findIndex((alert) => alert.id === newAlert.id) < 0) {
            alerts.value.push(newAlert);
        }
    }

    const removeAlert = (alertId: string) => {
        alerts.value = alerts.value.filter((alert) => alert.id !== alertId);
    }

    const globalAlerts = computed(() => {
        return alerts.value.filter((alert) => !alert.pageName);
    });

    const pageAlerts = computed(() => {
        return (routeName: string) => alerts.value.filter((alert) => !!alert.pageName);
    });

    return {
        addAlert,
        removeAlert,
        globalAlerts,
        pageAlerts,
    }
});
