import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { IConfig } from '@/app/shared/components/kl-upload/config';
import {IGuideline} from '@/app/shared/components/kl-upload/guideline';
import {computed, defineComponent, ref} from 'vue';
import klUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import {componentRef} from '@/app/shared/helpers/vue-typescript-util';

export default defineComponent({
    name: 'KlUploadClientside',
    emits: ['files-upload'],
    components: {
        KlUpload,
    },
    props: {
        guidelines: { type: Array as () => IGuideline[], default: null},
        modHideGuideLines: { type: Boolean, required: false, default: false },
        maxFiles: { type: Number, required: false, default: 1 },
        moreInfoLink: { type: String, required: false, default: null },
    },
    setup(props, { emit }) {

        const zoneUpload = ref<InstanceType<typeof klUpload>>(null);

        const enableMultiFiles = computed((): boolean => {
            return props.maxFiles > 1;
        });

        const config = computed((): IConfig => {
            return {
                url: 'dummy', // required (but not used) for underlying vue2Dropzone.js
                title: '',
                uploadLabel: enableMultiFiles.value ? 'Zone(s) toevoegen' : 'Zone toevoegen',
                uploadDragText: enableMultiFiles.value ? 'Sleep bestand(en) naar hier om toe te voegen.' : 'Sleep het bestand naar hier om toe te voegen.',
                guidelines: props.guidelines,
            };
        });

        const fileAdded = (file: File) => {
            // FIX for underlying components: otherwise a 'file drop' event would still trigger a POST call
            _removeAllFiles();

            // fileAdded & filesAdded are both called, so the 1 emit in filesAdded is sufficient
        }

        const filesAdded = (files: File[]) => {
            _removeAllFiles();

            emit('files-upload', files);
        }

        const onProcessing = (e) => {
            // just to be sure, but probably unnecessary
            _removeAllFiles();
        }

        const _removeAllFiles = () => {
            componentRef<InstanceType<typeof klUpload>>(zoneUpload).value.removeAllFiles();
        }

        return {
            zoneUpload,

            enableMultiFiles,
            config,

            fileAdded,
            filesAdded,
            onProcessing,
        }
    }
})
