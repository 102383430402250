import { defineComponent, ref } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";

export default defineComponent({
    props: {
        zone: {
            type: KlipApi.UnaZoneEditView,
            default: null,
            required: true
        }
    },
    setup: function (props) {
        const unaZone = ref<KlipApi.UnaZoneEditView>(props.zone);

        return{
            unaZone,
        }
    }
});
