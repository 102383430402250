import { AssetsStatusMri, MapRequestPmklMapAssetsStatus, Plans, MapRequestImklDataOrderStatus, PaymentTypes } from '@/api/klip-api.proxy';
import {EUserRole, useUserStore} from '@/app/shared/state/UserDataModule';
import { OrderImklMapRequestInput } from '@/api/klip-api.proxy';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import { mriShoppingCart } from '@/app/mri/mri-routes';
import { useShoppingCartStore } from '@/app/shared/state/ShoppingCartModule';
import { useCodeListStore } from '@/app/shared/state/CodeListModule';
import { useErrorStore } from '@/stores/error-store';
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {useRouter} from '@/plugins/routes';
import {useKlipApiProxy} from '@/plugins/proxy-client';

export default defineComponent({
    name: 'KlMriMapAssets',
    props: {
        assetsStatusData: {
            type: Object,
            required: true,
        },
        plans: {
            type: Object,
            required: true,
        },
        disableOrderImklForContactpuntUser: {
            type: Boolean,
            default: false,
            required: false
        },
        mapRequestId: {
            type: String,
            required: true,
        },
        isCitizen: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {

        const router = useRouter();

        const klipAppUrl: string = import.meta.env.VITE_LINKS_KLIPAPPURL;
        const imklInfoUrl: string = import.meta.env.VITE_LINKS_IMKLINFO;

        const _isImklAlreadyOrdered = ref<boolean>(false);
        const _imklOrderErrorMessage = ref<string>(null);

        const _imklOrderStatus_clientSide = ref<MapRequestImklDataOrderStatus>(null);

        watch(
            () => props.assetsStatusData,
            () => {
                _imklOrderStatus_clientSide.value = null;
            },
            { immediate: false, deep: true }
        )

        const _imklOrderStatus = computed((): MapRequestImklDataOrderStatus => {
           if (_imklOrderStatus_clientSide.value) {
               return _imklOrderStatus_clientSide.value;
           }
           if (!props.assetsStatusData) {
               return null;
           }
           return props.assetsStatusData.imklOrderStatus;
        });

        const showMapDisabled = computed((): boolean => {
            if (!props.assetsStatusData || !props.assetsStatusData.mapAssetsStatus) {
                return true;
            }
            return props.assetsStatusData.mapAssetsStatus !== MapRequestPmklMapAssetsStatus.Done;
        });

        const showMapDisabledExplanation = computed((): string => {
            if (!props.assetsStatusData) {
                return '';
            }

            switch (props.assetsStatusData.mapAssetsStatus) {
                case MapRequestPmklMapAssetsStatus.IsPending:
                    return 'Uw kaart wordt samengesteld';
                case MapRequestPmklMapAssetsStatus.NoPermission:
                    return 'U hebt geen toegang tot deze kaart';
                case MapRequestPmklMapAssetsStatus.Outdated:
                    return 'Deze planaanvraag kan niet meer bekeken worden op de kaart';
                case MapRequestPmklMapAssetsStatus.Error:
                    return 'Uw kaart kan momenteel niet aangemaakt worden. We lossen dit probleem zo snel mogelijk op';
                default:
                    return '';
            }
        });

        const showDownloadKlipDisabled = computed((): boolean => {
            if (!props.assetsStatusData || !props.assetsStatusData.pmklPackageStatus) {
                return false;
            }
            return props.assetsStatusData.pmklPackageStatus !== MapRequestPmklMapAssetsStatus.Done;
        });

        const showDownloadKlipDisabledExplanation = computed((): string => {
            if (!props.assetsStatusData) {
                return '';
            }

            switch (props.assetsStatusData.pmklPackageStatus) {
                case MapRequestPmklMapAssetsStatus.IsPending:
                    return 'Uw .klip-bestand wordt aangemaakt.';
                case MapRequestPmklMapAssetsStatus.NoPermission:
                    return 'U hebt geen toegang tot dit .klip-bestand.';
                case MapRequestPmklMapAssetsStatus.Outdated:
                    return 'Voor deze planaanvraag wordt geen .klip-bestand meer gemaakt.';
                case MapRequestPmklMapAssetsStatus.Error:
                    return 'Uw .klip-bestand kan momenteel niet worden aangemaakt. We lossen dit probleem zo snel mogelijk op.';
                default:
                    return '';
            }
        });

        const applyVat = computed((): boolean => {
            return useUserStore().applyVat;
        });

        const showDownloadImkl = computed((): boolean => {
            return !useUserStore().user.isCitizen && (_imklOrderStatus.value === MapRequestImklDataOrderStatus.Ordered);
        });

        const showOrderImkl = computed((): boolean => {
            if (useUserStore().user.isCitizen || !_imklOrderStatus.value || props.isCitizen) {
                return false;
            }
            return _imklOrderStatus.value === MapRequestImklDataOrderStatus.NotOrdered;
        });

        const showOrderImklDisabled = computed((): boolean => {
            return props.disableOrderImklForContactpuntUser || !useUserStore().hasRole([EUserRole.mri]);
        });

        const showPendingImkl = computed((): boolean => {
            if (!_imklOrderStatus.value) {
                return false;
            }
            return _imklOrderStatus.value === MapRequestImklDataOrderStatus.Pending;
        });

        const getImklPrice = computed((): number => {
            return useCodeListStore().mapRequestPrices?.imklPrice;
        });

        const hasImklOrderErrorMessage = computed((): boolean => {
            return _imklOrderErrorMessage.value !== null;
        });

        const getImklOrderErrorMessage = computed((): string => {
            return _imklOrderErrorMessage.value;
        });

        const showImklAlreadyOrdered = computed((): boolean => {
            return _isImklAlreadyOrdered.value;
        });


        onMounted(() => {
            useSignalrHub().subscribeToMapRequestNotifications(props.mapRequestId);
            useSignalrHub().subToEvent(EEventType.ImklDataOfMapRequestOrderedEvent, _setImklStatusOrdered);
        });

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.ImklDataOfMapRequestOrderedEvent);
            useSignalrHub().unSubscribeFromMapRequestNotifications(props.mapRequestId);
        });

        const fetchOpenMapRequestInViewerLink = () => {
            if (!showMapDisabled.value && props.plans && props.plans.openMapRequestUrl) {
                const routeData = router.resolve({ name: 'mriviewer', params: { mapRequestId: props.mapRequestId } });
                window.open(routeData.href, '_blank');
            }
        }

        const fetchDownloadImklFileUrl = () => {
            if (props.plans && props.plans.downloadImklFileUrl) {
                window.open(props.plans.downloadImklFileUrl);
            }
        }

        const fetchDownloadPmklFileUrl = () => {
            if (!showDownloadKlipDisabled.value && props.plans && props.plans.downloadPmklFileUrl) {
                window.open(props.plans.downloadPmklFileUrl);
            }
        }

        const _setImklStatusOrdered = () => {
            _setImklStatus(MapRequestImklDataOrderStatus.Ordered);
        }

        const _setImklStatus = (status: MapRequestImklDataOrderStatus) => {
            _imklOrderStatus_clientSide.value = status;
        }

        const orderImklData = () => {
            _setImklStatus(MapRequestImklDataOrderStatus.Pending);

            const input = new OrderImklMapRequestInput({
                mapRequestId: props.mapRequestId,
            });

            useErrorStore().setBypassError(['405']);
            useKlipApiProxy().mri_OrderImklFile(input).then(() => {
                useErrorStore().resetBypassError();
                if (useUserStore().paymentType === PaymentTypes.Online) {
                    useShoppingCartStore().updateShoppingCart().then(() => {
                        router.push({ name: mriShoppingCart.name });
                    });
                }
            }).catch((error) => {
                useErrorStore().resetBypassError();
                if (error.response && error.response.status && error.response.status === 405) {
                    if (error.response.data && error.response.data.result && error.response.data.result.KlipErrorCode) {
                        if (error.response.data.result.KlipErrorCode === 101) {
                            _isImklAlreadyOrdered.value = true;
                            _setImklStatus(MapRequestImklDataOrderStatus.Ordered);
                        } else if (error.response.data.result.KlipErrorCode === 200) {
                            _imklOrderErrorMessage.value = 'Voltooi of annuleer de betaling van uw winkelmandje voor u een nieuw item toevoegt.';
                            _setImklStatus(MapRequestImklDataOrderStatus.NotOrdered);
                        } else if (error.response.data.result.KlipErrorCode === 100) {
                            if (error.response.data.result.KlipErrorContent.ExistsInOwnShoppingCart) {
                                useShoppingCartStore().updateShoppingCart().then(() => {
                                    router.push({ name: mriShoppingCart.name });
                                });
                            } else {
                                _imklOrderErrorMessage.value = 'De IMKL-bestelling zit al in het winkelmandje van ' + error.response.data.result.KlipErrorContent.ShoppingCartOwnerName + ', u kan dit slechts eenmaal bestellen. Van zodra de betaling in orde is, kan u IMKL-data downloaden.';
                                _setImklStatus(MapRequestImklDataOrderStatus.NotOrdered);
                            }
                        }
                    }
                }
            }).finally(() => { useErrorStore().resetBypassError(); });
        }

        return {
            klipAppUrl,
            imklInfoUrl,

            showMapDisabled,
            showMapDisabledExplanation,
            showDownloadKlipDisabled,
            showDownloadKlipDisabledExplanation,
            applyVat,
            showDownloadImkl,
            showOrderImkl,
            showOrderImklDisabled,
            showPendingImkl,
            getImklPrice,
            hasImklOrderErrorMessage,
            getImklOrderErrorMessage,
            showImklAlreadyOrdered,

            fetchOpenMapRequestInViewerLink,
            fetchDownloadImklFileUrl,
            fetchDownloadPmklFileUrl,
            orderImklData,
        }
    }
})
