import {IApplicationMessages, IAlertNotification, UserImpersonationDto} from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import { useCodeListStore } from '../../state/CodeListModule';
import { useUserStore } from '@/app/shared/state/UserDataModule';
import dayjs from 'dayjs';
import {computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, ref} from 'vue';
import {useRouter} from '@/plugins/routes';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import {useKlipApiProxy} from '@/plugins/proxy-client';


export default defineComponent({
    name: 'KlApplicationMessages',
    props: {},
    setup() {
        const router = useRouter();
        const root = getCurrentInstance().proxy.$root;

        const sessionTimeoutLocalStorageKey: string = 'sessiontimeout_redirect';
        const isRequestingForImpersonation = ref<boolean>(false);
        const userImpersonation = ref<UserImpersonationDto>(null);
        const applicationMessages = ref<IApplicationMessages>(null);
        const isSessionTimeoutVisibleValue = ref<boolean>(false);

        const isBeta = computed(() => {
            return useCodeListStore().isBeta;
        });

        const betaMessage = computed((): IAlertNotification => {
            return {
                title: 'Let op! Dit is een test omgeving',
                message: 'Deze omgeving gebruik je enkel om te testen. Een echte planaanvraag doe je op <a href="https://klip.vlaanderen.be">klip.vlaanderen.be</a>',
                isClosable: false,
            };
        });

        const messages = computed((): IAlertNotification[] => {
            if (applicationMessages.value && applicationMessages.value.messages) {
                return applicationMessages.value.messages;
            }
            return [];
        });

        const whatsNew = computed(() => {
            if (applicationMessages.value) {
                return applicationMessages.value.whatsNew;
            }
            return null;
        });

        const isSessionTimeoutVisible = computed(() => {
            return isSessionTimeoutVisibleValue.value;
        });


        const closeMessage = (id: string) => {
            userLocalStorage.appendItemToArray('application_announcements', id);
            _removeMessage(id);
        }

        const login = () => {
            useSignalrHub().stop(`${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`);
        }


        const _getApplicationMessages = () => {
            useKlipApiProxy().messages_GetApplicationMessages().then((response) => {
                applicationMessages.value = response.result;
                if (!applicationMessages.value.messages && !applicationMessages.value.whatsNew) {
                    return;
                }

                const removedMessageIds: string[] = userLocalStorage.getItem('application_announcements', true) as string[];
                if (!removedMessageIds) {
                    return;
                }

                if (applicationMessages.value.messages) {
                    applicationMessages.value.messages = applicationMessages.value.messages.filter((message) => !removedMessageIds.includes(message.id));
                }

                if (applicationMessages.value.whatsNew && removedMessageIds.includes(applicationMessages.value.whatsNew.id)) {
                    applicationMessages.value.whatsNew = null;
                }
            });
        }

        const _checkAskForImpersonation = async () => {
            const userStore = useUserStore();
            const hasPendingImpersonationResult = await useKlipApiProxy().acmIdmAuth_HasPendingImpersonationConsent(userStore.user.klipUserId, userStore.organisationId);
            if(hasPendingImpersonationResult.isSuccessStatusCode && hasPendingImpersonationResult.result) {
                isRequestingForImpersonation.value = true;
                userImpersonation.value = hasPendingImpersonationResult.result;
            }
        }

        const _initApplicationMessagePolling = () => {
            if (!isSessionTimeoutVisibleValue.value) {
                setTimeout(() => {
                    _getApplicationMessages();
                    _initApplicationMessagePolling();
                }, 300000);
            }
        }

        const _initSessionTimeoutPolling = () => {
            setTimeout(() => {
                if (dayjs(useUserStore().user.sessionTimeout) < dayjs()) {
                    userLocalStorage.setItem(sessionTimeoutLocalStorageKey, router.currentRoute.path);
                    // Explicit call to disconnect from signalr
                    useSignalrHub().stop();
                    isSessionTimeoutVisibleValue.value = true;
                } else {
                    _initSessionTimeoutPolling();
                }
            }, 10000);
        }

        const _removeMessage = (id: string) => {
            applicationMessages.value.messages = applicationMessages.value.messages.filter((message) => message.id !== id);
        }

        const consentImpersonation = async () => {
            const userId = useUserStore().user.klipUserId;
            await useKlipApiProxy().acmIdmAuth_ChangeImpersonationConsent(userId, userImpersonation.value.id, true);
        };

        const revokeImpersonation = async () => {
            const userId = useUserStore().user.klipUserId;
            await useKlipApiProxy().acmIdmAuth_ChangeImpersonationConsent(userId, userImpersonation.value.id, false);
        };

        const _subscribeToSignalrEvents = () => {
            const user = useUserStore().user;
            const groupName = user.isCitizen ? user.klipUserId : `${user.klipUserId}_${user.klipOrganisationApiId}`;
            useSignalrHub().subscribeToCustomIdNotifications(groupName, 'AskForImpersonationEvent');
            useSignalrHub().subToEvent(EEventType.AskForImpersonationEvent, _checkAskForImpersonation);
        }

        onMounted(async () => {
            _getApplicationMessages();
            _initApplicationMessagePolling();
            _initSessionTimeoutPolling();
            _subscribeToSignalrEvents();
            setTimeout(async () => {
                // Hack for vl-modal problem.
                // Problem: grey overlay will disappear if the modal is open when another modal is rendered on the page (kl-locationpicker).
                // Solution: wait until the first page is loaded & rendered.
                await _checkAskForImpersonation();
            }, 5000);
            root.$on('closed', () => {
                if (applicationMessages.value.whatsNew) {
                    userLocalStorage.appendItemToArray('application_announcements', applicationMessages.value.whatsNew.id);
                }
            });
        });

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.AskForImpersonationEvent);
            useSignalrHub().unSubscribeFromMapRequestNotifications(useUserStore().user.klipUserId);
        });

        return {
            applicationMessages,
            isBeta,
            betaMessage,
            messages,
            whatsNew,
            isSessionTimeoutVisible,
            closeMessage,
            login,
            isRequestingForImpersonation,
            userImpersonation,
            consentImpersonation,
            revokeImpersonation,
        }
    }
})
