import Guid from "@/app/shared/helpers/guid";
import { ComputedRef, computed, defineComponent, ref, watch } from "vue"
import { OrganisationsSearchField } from "./organisations-search-field";
import KlSearchOrganisationFilters from "./kl-search-organisations-filters-enum";
import { debounce } from "lodash";

export default defineComponent({
    props: {
        defaultFilter: {
            type: OrganisationsSearchField,
            default: null,
            required: true
        },
        debounceWait: {
            type: Number,
            default: 500
        }
    },
    emits: ['search'],
    setup(props, { emit }) {
        const searchFields: ICodeListValueOrder[] = [
            <ICodeListValueOrder>{
                isDefaultValue: true,
                key: KlSearchOrganisationFilters.OrganisatieFilter,
                label: '(Sub)organisatie'
            },
            <ICodeListValueOrder>{
                key: KlSearchOrganisationFilters.OrganisatieCodeFilter,
                label: 'OVO, KBO, Ondernemingsnummer'
            },
            <ICodeListValueOrder>{
                key: KlSearchOrganisationFilters.UtilityNetworkAuthorityZoneFilter,
                label: 'KLB-zone'
            }
        ];

        const keyword = ref<string>('');
        const searchField = ref<string>(KlSearchOrganisationFilters.OrganisatieFilter);
        const offset = ref<number>(0);

        const debouncedSearch = debounce(() => {
            emit('search', {
                searchField: searchField.value,
                keyword: keyword.value,
                onlyRootOrganisations: onlyRootOrganisations.value,
                offset: offset.value
            } as OrganisationsSearchField);
        }, props.debounceWait);

        const searchFieldChanged = () => {
            debouncedSearch();
        }

        const search = () => {
            debouncedSearch()
        }

        const defaultFilterChanged = (filter: OrganisationsSearchField) => {
            if (filter == null) {
                return;
            }
            if (filter.keyword) {
                keyword.value = filter.keyword;
            }
            if (filter.searchField) {
                searchField.value = filter.searchField;
            }
            if (filter.offset > 0) {
                offset.value = filter.offset;
            }
            onlyRootOrganisations.value = filter.onlyRootOrganisations;
        }

        const reset = () => {
            searchField.value = KlSearchOrganisationFilters.OrganisatieFilter;
            keyword.value = null;
        }

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        }

        const onlyRootOrganisations = ref<boolean>();

        const watchFields: ComputedRef<[string, boolean]> = computed(() => {
            return [
                keyword.value,
                onlyRootOrganisations.value
            ];
        });

        watch(watchFields, search, { immediate: false, deep: true });
        watch(props.defaultFilter, defaultFilterChanged, { immediate: true, deep: true });

        return {
            searchFields,
            searchField,
            keyword,
            onlyRootOrganisations,
            search,
            reset,
            uniqueFieldId,
            searchFieldChanged
        }
    }
})
