import { computed, defineComponent, onMounted, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { escape } from 'html-escaper';
import { KlRouteConfig } from "@/router/router-vue";
import KlDrawZoneMap, {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {isEmpty} from 'lodash';

export default defineComponent({
    name: 'KlPdaZoneList',
    props: {
        zones: {
            type: Array<KlipApi.ZoneListItem>,
            default: [],
            required: true
        },
        fetching: {
            type: Boolean,
            default: false,
            required: true
        },
        pdaZoneDetailRoute: {
            type: Object as () => KlRouteConfig,
            default: null,
            required: true
        },
        organisationId: {
            type: String,
            default: null,
            required: false
        }
    },
    setup(props) {

        const pdaDrawZonesMap = ref<InstanceType<typeof KlDrawZoneMap>>(null);

        const pdaZones = computed(() => {
            if (isEmpty(props.zones)) {
                return [];
            }
            return props.zones;
        });

        const drawZones = computed(() => {
            return pdaZones.value.map((pdaZone: KlipApi.IZoneListItem): IDrawZoneMapGeometry => {

                return {
                    name: pdaZone.name,
                    wkt: pdaZone.wkt,
                    orgZoneInfo: {
                        isKlim: false,
                        guid: pdaZone.id,
                        namespace: pdaZone.name,
                        isActive: true,
                        editLink: {
                            name: routePdaZoneId.value.name,
                            params: {
                                zoneId: pdaZone.id,
                                zoneName: escape(pdaZone.name),
                                organisationId: props.organisationId
                            },
                        },
                    }
                }
            });
        });

        const showEmptyState = computed((): boolean => {
            return isEmpty(pdaZones.value) && !props.fetching;
        });

        const routePdaZoneId = computed((): KlRouteConfig => {
            return props.pdaZoneDetailRoute;
        });

        watch(
            drawZones,
            (newZones) => pdaDrawZonesMap.value?.updateInputGeometries(newZones),
            { immediate: false }
        );

        return {
            drawZones,
            pdaDrawZonesMap,
            showEmptyState,
        };
    }
});
