export class KlAbortController {

    private _abortController = null;

    public abortAndCreateNewSignal() {
        if (this._abortController) {
            this._abortController.abort();
        }
        this._abortController = new AbortController();
        return this._abortController.signal;
    }
}

export function isAbortError(error: any) {
    return error?.code === 'ERR_CANCELED';
}

export function handleAbortError(error: any) {
    if (isAbortError(error)) {
        console.info(`request aborted: ${error.config?.url}`);
    }
    else {
        throw error;
    }
}
