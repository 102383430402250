import { computed, defineComponent } from 'vue';
import { InvoiceDetails } from "@/app/checkout/components/invoice-details";
import { escape } from "html-escaper";
import { useUserStore } from "@/app/shared/state/UserDataModule";
import {required} from "vee-validate/dist/rules";

export default defineComponent({
    computed: {
        required() {
            return required
        }
    },
    props: {
        invoiceDetails: {
            type: InvoiceDetails,
            default: {
                address: null,
                emailAddress: null,
                organisation: null,
                vatNumber: null,
                attention: null,
                rememberContactDetails: false,
                editInvoiceDetailsMode: true,
                useAlternativeEmail: false,
                invoiceDetailsPrefilledByCookie: false,
                ownReference: null,
                isTopupPrepaid: false
            },
            required: true
        }
    },
    setup(props) {
        const user = useUserStore().user;
        const userEmail = user.email;
        const isCitizen = user.isCitizen;
        const noVatNumberPresent = !user.organisationKboNumber;

        const sepaValidationRegex = /^[a-zA-Z0-9\/\-?:().,'+\s]+$/i;

        const emailAddressAnnotation = computed(() => {
            return isCitizen
                ? ''
                : 'Vul hier een algemeen e-mailadres in waarop uw organisatie facturatie wenst te ontvangen.';
        });

        const ownReferenceAnnotation = computed(() => {
            return isCitizen
                ? ''
                : 'Optioneel kan u hier een intern bestelbon- of PO-nummer meegeven.';
        });

        const showOwnReference = computed(() => {
            return !isCitizen && !props.invoiceDetails.isTopupPrepaid;
        });

        const title = computed(() => {
            if (isCitizen) {
                return `${escape(user.firstName)} ${escape(user.lastName)}`;
            } else {
                const ovoOrKboOrOrganisationCode = user.organisationOvoCode ? `${escape(user.organisationOvoCode)}` : user.organisationKboNumber ? `${escape(user.organisationKboNumber)}` : `${escape(user.organisationCode)}`;
                return `${escape(user.organisation)} - ${ovoOrKboOrOrganisationCode}${user.organisationKboNumber ? `<br /> BTW ${escape(user.organisationVatNumber)}` : ''}`;
            }
        });

        return {
            title,
            userEmail,
            isCitizen,
            noVatNumberPresent,
            emailAddressAnnotation,
            showOwnReference,
            ownReferenceAnnotation,
            sepaValidationRegex
        }
    }
});
