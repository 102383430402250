import {EUserRole, useUserStore} from "@/app/shared/state/UserDataModule";
import {useShoppingCartStore} from "@/app/shared/state/ShoppingCartModule";
import {useCodeListStore} from "@/app/shared/state/CodeListModule";
import {ProcessingTopUp, ProcessingShoppingCart} from "@/app/checkout/checkout-routes";
import { useErrorStore } from "@/stores/error-store";
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';
import {mriShoppingCart} from "@/app/mri/mri-routes";
import {useSignalrHub} from "@/plugins/signalr";

export default defineComponent({
    name: 'FunctionalHeader',
    props: {},
    setup(props) {

        const route = useRoute();
        const router = useRouter();

        const hasErrors = computed((): boolean => {
            return useErrorStore().hasErrors || (!!useUserStore().user && (useUserStore().user.roleError || !!useUserStore().user.validationError));
        });

        const isUpdating = computed((): boolean => {
            return useUserStore().isUpdating;
        });

        const impersonatingAsUserName = computed((): string => {
            return `${useUserStore().user.firstName} ${useUserStore().user.lastName}`;
        });

        const impersonatingAsOrganisationName = computed((): string => {
            return useUserStore().user.organisation;
        });

        const isImpersonating = computed((): boolean => {
            return useUserStore().user.isImpersonating;
        });

        const loginLink = computed((): string => {
            return `${window.location.origin}/auth/login`
        });


        const logoutLink = () => {
            useSignalrHub().stop(`${window.location.origin}/auth/logout`);
        }


        const isLoggedOn = computed((): boolean => {
            return useUserStore().loggedOn;
        });

        const isAdmin = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.admin, EUserRole.helpdesk]);
        });

        const showAdminNavigation = computed((): boolean => {
            return isAdmin.value && route.path.startsWith("/admin");
        });

        const numberItemsInCart = computed((): number => {
            if (!isLoggedOn.value) {
                return 0;
            }
            return useShoppingCartStore().numberOfItems;
        });

        const functionalHeaderClassName = computed((): string => {
            const classList = ["kl-functional-header"];

            if (useCodeListStore().isBeta) {
                classList.push("beta");
            }
            if (!showBreadcrumbNavigation.value && !showAdminNavigation.value) {
                classList.push("kl-functional-header--right");
            }

            return classList.join(" ");
        });

        const shoppingCartActive = computed((): boolean => {
            if (hasErrors.value) {
                return false;
            }
            if (!isLoggedOn.value) {
                return false;
            }
            if (route.name === ProcessingTopUp.name || route.name === ProcessingShoppingCart.name) {
                return false;
            }
            return useShoppingCartStore().isShoppingCartActive;
        });

        const showBreadcrumbNavigation = computed((): boolean => {
            if (hasErrors.value) {
                return false;
            }
            if (!isLoggedOn.value) {
                return true;
            }
            return !showAdminNavigation.value
        });

        const navigateToShoppingCart = async () => {
            await router.push({name: mriShoppingCart.name});
        };

        return {
            hasErrors,
            isUpdating,
            loginLink,
            logoutLink,
            isLoggedOn,
            isAdmin,
            isImpersonating,
            impersonatingAsUserName,
            impersonatingAsOrganisationName,
            showAdminNavigation,
            numberItemsInCart,
            functionalHeaderClassName,
            shoppingCartActive,
            showBreadcrumbNavigation,
            navigateToShoppingCart,
        }
    }
})
