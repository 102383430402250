import Vue from 'vue';
import { IAlertNotification, useAlertStore } from '@/app/shared/state/AlertModule';

export interface IAlertHandler {
    addAlert(newAlert: IAlertNotification): void;
    removeAlert(alertId: string): void;
}

export const AlertHandler: IAlertHandler = {
    addAlert(newAlert: IAlertNotification) {
        useAlertStore().addAlert(newAlert);
    },
    removeAlert(alertId: string) {
        useAlertStore().removeAlert(alertId);
    },
};

export default {
    install(vue: typeof Vue): void {
        vue.prototype.$alertHandler = AlertHandler;
    },
};

export function useAlertHandler() {
    return AlertHandler;
}
