import dayjs from 'dayjs';
import {computed, defineComponent, nextTick, reactive, ref} from 'vue';
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {first} from 'lodash-es';
import KlDrawZoneMapUtils from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map-utils';
import {IGeometry} from '@/api/klip-api.proxy';

export interface IGeometryExtended extends IGeometry {
    isDeleted?: boolean;
}

export interface IFlatGeometry {
    activation: {
        geometryActivationDate: string[];
    };
    reference: {
        geometryVersion: string;
        geometryPrecision: number;
        geometryLastModificationDate: string[];
        geometryReference: string;
    };
    file: {
        geometryWkt?: string;
    };
}

interface IGeometryOnMapData {
    activation: {
        label: string;
        shortLabel?: string;
        fields: {
            geometryActivationDate: string[];
        };
    };
    reference: {
        label: string;
        shortLabel?: string;
        fields: {
            geometryVersion: string;
            geometryPrecision: number;
            geometryLastModificationDate: string[];
            geometryReference: string;
        };
    };
    file: {
        label: string;
        shortLabel?: string;
        fields: {
            geometryWkt?: string;
        };
    };
}

export default defineComponent({
    name: 'KlCreateGeometryOnMap',
    emits: ['revert', 'geometry-update', 'geometry-upload-finished'],
    props: {
        modRevertable: { type: Boolean, default: false },
        showActions: { type: Boolean, default: true },
    },
    setup(props, { emit }) {

        const geometryActivationMinDate = dayjs().add(1, 'day').format('DD-MM-YYYY');
        const geometryLastModificationMaxDate = dayjs().format('DD-MM-YYYY');

        const geometryErrorMessages = ref<string[]>([]);
        const validatingGeometry = ref<boolean>(false);

        const steps = reactive<IGeometryOnMapData>({
            activation: {
                label: 'Activering geometrie',
                fields: { geometryActivationDate: null },
            },
            reference: {
                label: 'Uw referenties',
                fields: {
                    geometryVersion: null,
                    geometryPrecision: null,
                    geometryLastModificationDate: null,
                    geometryReference: null,
                },
            },
            file: {
                label: 'Laad uw bestand op',
                shortLabel: 'Opladen',
                fields: {
                    geometryWkt: null,
                },
            },
        });

        const geometryFields = computed(() => {
            return {
                activation: steps.activation.fields,
                reference: steps.reference.fields,
                file: steps.file.fields,
            };
        });

        const invalidWkt = computed(() => {
            return !geometryFields.value.file.geometryWkt;
        });

        const validateGeometry = async (): Promise<boolean> => {
            _clearGeometryErrors();

            validatingGeometry.value = true;

            // 'validate' can block the browser UI, so bump the heap to show loaders/waiting state
            await new Promise(r => setTimeout(r, 0));

            const rules = 'zone_geometry_smaller_than_x_bytes:104857600|zone_partially_in_flanders';
            const validationResult = await KlDrawZoneMapUtils.validateWktGeometry(geometryFields.value?.file?.geometryWkt, true, rules);

            geometryErrorMessages.value = validationResult.errors;

            validatingGeometry.value = false;
            return validationResult.valid;
        }

        const submit = async (): Promise<boolean> => {

            if (!await validateGeometry()) {
                return false;
            }

            // "emit('geometry-update')"
            // > is voor create zone flow in kl-una-create-edit-zone, maar kan aangepast worden
            emit('geometry-update', geometryFields.value);

            const geometry: IGeometryExtended = {
                wkt: geometryFields.value.file.geometryWkt,
                activationDate: geometryFields.value.activation.geometryActivationDate
                    ? geometryFields.value.activation.geometryActivationDate[0]
                    : null,
                lastModificationDate: geometryFields.value.reference.geometryLastModificationDate
                    ? geometryFields.value.reference.geometryLastModificationDate[0]
                    : null,
                precision: geometryFields.value.reference.geometryPrecision,
                reference: geometryFields.value.reference.geometryReference,
                version: geometryFields.value.reference.geometryVersion,
                downloadUrls: null, // uploadStatusResponse.result.downloadUrls,
                isActive: false,
                isDeleted: false,
            };

            emit('geometry-upload-finished', geometry);

            return true;
        }

        const revert = () => {
            emit('revert');
        }

        const onNewGeometryChanged = (newGeometryZones: IDrawZoneMapGeometry[]) => {
            _clearGeometryErrors();
            geometryFields.value.file.geometryWkt = first(newGeometryZones)?.wkt;
        }

        const _clearGeometryErrors = () => {
            geometryErrorMessages.value = [];
        }


        return {
            geometryActivationMinDate,
            geometryLastModificationMaxDate,
            steps,
            geometryFields,

            validatingGeometry,
            geometryErrorMessages,

            invalidWkt,

            validateGeometry,
            submit,
            revert,

            onNewGeometryChanged,
        }
    }
})
