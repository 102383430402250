import {computed, defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { useRoute } from "@/plugins/routes";
import { IUnaZone } from "./una-zone-model";
import { AdminSubOrganisationDetailUnaZone, AdminSubOrganisationDetailUnaKlimZone } from "@/app/admin/admin-routes";
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import KlDrawZoneMap from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map.vue';
import {KlRouteConfig} from '@/router/router-vue';
import {componentRef} from '@/app/shared/helpers/vue-typescript-util';

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.SubOrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');

        const klbZonesMap = ref<InstanceType<typeof KlDrawZoneMap>>();
        const klbZonesKey = ref<string>('klbZonesKey');

        const displayName = ref<String>(props.organisation.displayName);
        const confirmation = ref<boolean>(props.organisation.unaData.confirmation);
        const confirmationExampleUri = ref<string>(props.organisation.unaData.confirmationExampleUri);
        const subjectSigningCertificate = ref<string>(props.organisation.subjectSigningCertificate);
        const route = useRoute();
        const zonesFetching = ref<boolean>(true);
        const isSending = ref<boolean>(false);
        const onlySearchActiveZones = ref<boolean>(true);
        const zones = ref<IUnaZone[]>([]);
        const routeUnaKlimZone = ref<KlRouteConfig>(AdminSubOrganisationDetailUnaKlimZone);
        const routeUnaZone = ref<KlRouteConfig>(AdminSubOrganisationDetailUnaZone);

        const drawZones = computed(() => {
            const today = new Date();

            const myNamespaces = zones.value;
            const allDrawZones = myNamespaces.map((zone, index): IDrawZoneMapGeometry => {

                const activationDate = new Date(zone.zoneGeometryActivationDate);
                const isActive = !zone.isArchived && (zone.isKlimZone || !zone.zoneGeometryActivationDate || (activationDate < today));

                return {
                    name: zone.zoneName,
                    wkt: zone.zoneGeometry,
                    orgZoneInfo: {
                        isKlim: zone.isKlimZone,
                        guid: zone.zoneId,
                        namespace: zone.namespace,
                        isActive: isActive,
                        isArchived: zone.isArchived,
                        willBeActivatedOn: !isActive ? activationDate : undefined,
                        editLink: {
                            name: zone.link?.route?.name,
                            params: { zoneId: zone.zoneId },
                        },
                    }
                }
            });

            let filteredDrawZones = allDrawZones;
            if (onlySearchActiveZones.value) {
                filteredDrawZones = allDrawZones.filter((cz: IDrawZoneMapGeometry): boolean => cz.orgZoneInfo.isActive);
            }
            return filteredDrawZones;
        });

        const search = async (value: boolean) => {
            onlySearchActiveZones.value = value;
            await _loadZones();

            // hack: force an update of the kl-draw-zone-map component
            // because vue-custom-scrollbar doesn't properly update the scrollbar
            klbZonesKey.value = klbZonesKey.value + '*';
            // alternative for hack (how it is supposed to work)
            // await componentRef<InstanceType<typeof KlDrawZoneMap>>(klbZonesMap).value.updateInputGeometries(drawZones.value);
        };

        const _loadZones = async () => {
            const unaZones = (await proxy.organisation_LoadUnaZonesForOrganisation(props.organisation.organisationId, !onlySearchActiveZones.value)).result;
            zones.value = unaZones
                .filter((item) => item.zoneId !== route.params.removedItem)
                .sort((a, b) => {
                    // first order on isArchived
                    if (a.isArchived !== b.isArchived) {
                        return a.isArchived ? 1 : -1;
                    }
                    // then order on isKlimZone
                    if (a.isKlimZone !== b.isKlimZone) {
                        return a.isKlimZone ? -1 : 1;
                    }

                    return 0;
                })
                .map((zone): IUnaZone => ({
                    zoneId: zone.zoneId,
                    zoneName: zone.zoneName,
                    namespace: zone.namespace,
                    isKlimZone: zone.isKlimZone,
                    unaId: zone.unaId,
                    zoneGeometryActivationDate: zone.zoneGeometryActivationDate,
                    zoneGeometry: zone.zoneGeometry,
                    isArchived: zone.isArchived,
                    link: {
                        route: zone.isKlimZone ? routeUnaKlimZone : routeUnaZone,
                        params: {
                            organisationId: props.organisation.organisationId,
                            zoneId: zone.zoneId,
                        },
                    },
                }));

            zonesFetching.value = false;
        }

        const updateConfirmationInUnaZoneSettings = async () => {
            isSending.value = true;

            const input = KlipApi.UpdateConfirmationInUnaZoneSettingsInput.fromJS({
                unaId: props.organisation.unaData.unaId,
                confirmation: confirmation.value,
            });

            await proxy.operations_UpdateConfirmationInUnaZoneSettings(input)
            isSending.value = false;
        }

        onMounted(async () => {
            await _loadZones();
        });

        return {
            klbZonesMap,
            klbZonesKey,

            displayName,
            confirmation,
            isSending,
            confirmationExampleUri,
            subjectSigningCertificate,
            zones,
            zonesFetching,
            updateConfirmationInUnaZoneSettings,
            search,

            drawZones,
            onlySearchActiveZones,
        };
    }
});

