import Vue from 'vue';
import Router from 'vue-router';
import { auth } from './guards';
import {EUserRole, useUserStore} from '@/app/shared/state/UserDataModule';
import { KlRouteConfig } from '@/router/router-vue';
import { usePaymentStatusStore_toBeRemoved } from '@/app/shared/state/PaymentStatusModule';
import { useErrorStore } from '@/stores/error-store';


export const klipIndex: KlRouteConfig = {
    path: '/',
    name: 'klip',
    meta: { authorize: { roles: [] } },
    beforeEnter: async (to, from, next) => {
        // Redirect to / mri, /pda or /una
        // pages depending on the user role / scope
        const newRoute = useUserStore().scope;
        const isOrgManagerOnly = useUserStore().hasRole([EUserRole.org_manager]) &&
            !useUserStore().hasRole([EUserRole.una_operator]) &&
            !useUserStore().hasRole([EUserRole.una_reader]) &&
            !useUserStore().hasRole([EUserRole.una_manager]) &&
            !useUserStore().hasRole([EUserRole.pda_manager]) &&
            !useUserStore().hasRole([EUserRole.mri]) &&
            !useUserStore().hasRole([EUserRole.mrr]);

        if (isOrgManagerOnly) {
            next({ path: `/settings` });
        }

        if (newRoute) {
            if (from.name === newRoute) {
                history.pushState(
                    {},
                    null,
                    from.path,
                );
                next({ path: `/${newRoute}` });
            } else {
                next({ path: `/${newRoute}` });
            }
        }
    },
};

const router = new Router({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: [klipIndex],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    Vue.prototype.$Progress?.start();
    // Support for old HashTagUrls (ignore hashes later in path)
    if (to.hash.substr(0, 2) === '#/') {
        const path = to.hash.substr(2);
        return next(path);
    }

    if (to.meta && to.meta.allowAnonymous) {
        next();
    } else {
        await auth(to, from, next);
        const scope = ['una', 'pda', 'mri'].find((item) => {
            return to.path.startsWith(`/${item}`);
        });
        if (scope) { useUserStore().setScope(scope); }
    }
});

router.afterEach((to, from) => {
    useErrorStore().resetAll();

    if ((from.name !== 'payment' && from.name !== 'checkout' && from.name !== 'mrimaprequestcreate') && usePaymentStatusStore_toBeRemoved().isPaymentMessagesVisible) {
        usePaymentStatusStore_toBeRemoved().clearPaymentMessages();
    }
    // The prepaid payment message needs to be cleared when coming from anywhere but the payment or prepaid view
    // But it doesn't need to be cleared when going from prepaid to checkoutprepaid
    if (((from.name !== 'payment' && from.name !== 'prepaid') || (from.name === 'prepaid' && to.name !== 'checkoutprepaid')) && (usePaymentStatusStore_toBeRemoved().hasPrepaidAmount || usePaymentStatusStore_toBeRemoved().hasPrepaidPaymentMessageData)) {
        if (from.name !== 'checkoutprepaid' && to.name !== 'prepaid') {
            usePaymentStatusStore_toBeRemoved().clearPrepaidAmount();
        }
        usePaymentStatusStore_toBeRemoved().clearPrepaidPaymentMessage();
    }

    Vue.prototype.$Progress?.finish();
});

Vue.use(Router);

export default router;
