import router from '@/router/router';
import { signalrHub } from '@/plugins/signalr';
import {
    EnvelopeOfBoolean,
    IUserData,
    IEnvelopeOfUserData,
    PaymentTypes
} from '@/api/klip-api.proxy';
import { useCodeListStore } from './CodeListModule';
import userLocalStorage from '@/storage/user-local-storage';
import { useShoppingCartStore } from './ShoppingCartModule';
import { klipApiProxy } from '@/plugins/proxy-client';
import {useFeatureToggleStore} from '@/stores/feature-toggle-store';
import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';


export enum EUserRole {
    mri = 'mri',
    mrr = 'mrr',
    una_manager = 'una_manager',
    una_operator = 'una_operator',
    una_reader = 'una_reader',
    klim_admin = 'klim_admin',
    pda_manager = 'pda_manager',
    helpdesk = 'helpdesk',
    admin = 'admin',
    org_manager = 'org_manager',
}


export const useUserStore = defineStore('user-store', () => {

    const user: Ref<IUserData> = ref({});
    const scope: Ref<string> = ref('');

    const isUpdating: Ref<boolean> = ref(false);
    const isAuthenticated: Ref<boolean> = ref(false);
    const isCheckingAuthentication: Ref<boolean> = ref(true);


    const fullName = computed((): string => {
        let fullName = '';
        if (user.value.firstName) {
            fullName += user.value.firstName;
        }

        if (user.value.lastName) {
            if (user.value.firstName) {
                fullName += ' ';
            }
            fullName += user.value.lastName;
        }
        return fullName;
    });

    const loggedOn = computed((): boolean => {
        if (!user.value) {
            return false;
        }
        return !!user.value.klipUserId;
    });

    const applyVat = computed((): boolean => {
        return !!user.value && user.value.applyVat == true;
    });

    const vatPercentage = computed((): number => {
        return !!user.value && !!user.value.vatPercentage ? user.value.vatPercentage : 0;
    });

    const paymentType = computed((): PaymentTypes => {
        if (!user.value) {
            return PaymentTypes.None;
        }
        return user.value.paymentType;
    });


    const authenticated = computed((): boolean => {
        return isAuthenticated.value;
    });

    const scopes = computed(() => {
        const scopes = [];
        if (hasRole([EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin])) {
            scopes.push({
                key: 'una',
                abbr: 'KLB',
                label: 'Kabel- en leidingbeheerder',
            });
        }
        if (hasRole([EUserRole.pda_manager])) {
            scopes.push({
                key: 'pda',
                abbr: 'ODB',
                label: 'Openbaar Domeinbeheerder',
            });
        }
        if (hasRole([EUserRole.mri, EUserRole.mrr])) {
            scopes.push({
                key: 'mri',
                abbr: 'PAV',
                label: 'Planaanvrager',
            });
        }
        if (hasRole([EUserRole.helpdesk, EUserRole.admin])) {
            scopes.push({
                key: 'admin',
                abbr: 'CP',
                label: 'Contactpunt',
            });
        }
        return scopes;
    });

    const userId = computed(() => {
        return user.value.klipUserId;
    });

    const organisationId = computed(() => {
        return user.value.klipOrganisationApiId;
    });

    const isContactpuntUser = computed((): boolean => {
        return user.value.isContactPunt || hasRole([EUserRole.helpdesk]) || hasRole([EUserRole.admin]);
    });


    const hasRole = (roles: EUserRole[]): boolean => {
        if (roles.length > 0) {
            if (user.value && user.value.roles) {
                return _hasRole(user.value, roles);
            }
            return false;
        }
        return true;
    }


    const updateUserProfile = async (hardRefresh?: boolean) => {
        _updatingProfile(true);
        return klipApiProxy.acmIdmAuth_GetUserData(hardRefresh)
            .then(async (userDataEnvelope: IEnvelopeOfUserData) => {
                const userData = userDataEnvelope.result;
                userLocalStorage.setItem('klip_visited', true);
                _mutationUpdateUserProfile(userData);
                if (signalrHub) {
                    if (signalrHub.connected) {
                        signalrHub.subscribeToContactNotifications(userData.klipUserId);
                        if (!userData.isCitizen) signalrHub.subscribeToOrganisationNotifications(userData.klipOrganisationApiId);
                    } else {
                        signalrHub.eventEmitter.on('connected', () => {
                            signalrHub.subscribeToContactNotifications(userData.klipUserId);
                            if (!userData.isCitizen) signalrHub.subscribeToOrganisationNotifications(userData.klipOrganisationApiId);
                        });
                    }
                }
                await useFeatureToggleStore().fetch();
                await useCodeListStore().updateCodeList();
                _getScope(userData);
                await useShoppingCartStore().updateShoppingCart();
            }).finally(() => {
                _updatingProfile(false);
            });
    }

    const isUserAuthenticated =  async (): Promise<EnvelopeOfBoolean> => {
        updateIsCheckingAuthentication(true);
        return  klipApiProxy.acmIdmAuth_IsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated.result) {
                signalrHub.start();
            }
            _updateIsAuthenticated(isAuthenticated.result);
            return Promise.resolve(isAuthenticated);
        });
    }

    const setShoppingCartInvisible = () => {
        user.value.isShoppingCartVisible = false;
    }

    const _mutationUpdateUserProfile = (userData: IUserData) => {
        user.value = userData;
    }

    const _hasRole = (userData: IUserData, roles: EUserRole[]): boolean => {
        return userData.roles.some((role) => {
            const eRole = EUserRole[role];
            console.assert(!!eRole, `_hasRole(${role}): unknown role '${role}'`);
            return roles.includes(eRole);
        });
    }

    const _getScope = (userData: IUserData) => {
        if (localStorage && userData && userData.klipUserId) {
            const scopeFromLocalStorage = userLocalStorage.getItem('scope');
            //check if the user still has the correct roles when loading the scope from the localstorage.
            switch (scopeFromLocalStorage) {
                case 'mri':
                    if (_hasRole(userData, [EUserRole.mri, EUserRole.mrr])) {
                        scope.value = scopeFromLocalStorage;
                    }
                    break;
                case 'una':
                    if (_hasRole(userData, [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin])) {
                        scope.value = scopeFromLocalStorage;
                    }
                    break;
                case 'pda':
                    if (_hasRole(userData, [EUserRole.pda_manager])) {
                        scope.value = scopeFromLocalStorage;
                    }
                    break;
                case 'admin':
                    if (_hasRole(userData, [EUserRole.helpdesk, EUserRole.admin])) {
                        scope.value = scopeFromLocalStorage;
                    }
                    break;
            }
        }
        if (!scope.value) {
            scope.value =
                _hasRole(userData, [EUserRole.mri, EUserRole.mrr]) ? 'mri' :
                    _hasRole(userData, [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin]) ? 'una' :
                        _hasRole(userData, [EUserRole.pda_manager]) ? 'pda' :
                            _hasRole(userData, [EUserRole.helpdesk, EUserRole.admin]) ? 'admin' : '';
        }
    }

    const setScope = (newScope: string) => {
        if (localStorage && user.value && user.value.klipUserId) {
            userLocalStorage.setItem('scope', newScope);
        }
        scope.value = newScope;
    }

    const _updatingProfile = (updating: boolean) => {
        isUpdating.value = updating;
    }

    // TODO: plaats waar dit gebruikt wordt mag verhuizen naar deze store?
    const updateIsCheckingAuthentication = (isChecking: boolean) => {
        isCheckingAuthentication.value = isChecking;
    }

    const _updateIsAuthenticated = (authenticated: boolean) => {
        isAuthenticated.value = authenticated;
    }

    const cleanProfile = (redirect = true) => {
        user.value = {};
        if (redirect) {
            router.push('/');
        }
    }

    const $mockUser = (userData: IUserData) => {
        _mutationUpdateUserProfile(userData);
    }

    const $mockIsAuthenticated = (authenticated: boolean) => {
        _updateIsAuthenticated(authenticated);
    }


    return {
        user,
        scope,
        isUpdating,
        isAuthenticated,
        isCheckingAuthentication,

        fullName,
        loggedOn,
        applyVat,
        vatPercentage,
        paymentType,
        authenticated,
        scopes,
        userId,
        organisationId,
        isContactpuntUser,

        hasRole,

        updateUserProfile,
        isUserAuthenticated,
        setShoppingCartInvisible,
        setScope,
        cleanProfile,

        updateIsCheckingAuthentication,

        $mockUser,
        $mockIsAuthenticated,
    }
});
