import {useUserStore} from '@/app/shared/state/UserDataModule';
import {useErrorStore} from '@/stores/error-store';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useRouter} from '@/plugins/routes';
import {useSignalrHub} from '@/plugins/signalr';

export default defineComponent({
    name: 'KlApplicationErrors',
    props: {},
    setup() {

        const router = useRouter();

        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;

        const isError401 = ref<boolean>(false);
        const isError403 = ref<boolean>(false);
        const isError404 = ref<boolean>(false);
        const isError400Or405Or500 = ref<boolean>(false);
        const isRoleError = ref<boolean>(false);


        const statusCode = computed(() => {
            return useErrorStore().lastStatusCode;
        });

        const correlationId = computed(() => {
            return useErrorStore().lastCorrelationId;
        });

        const validationError = computed(() => {
            return useUserStore().user.validationError;
        });

        const username = computed((): string => {
            return useUserStore().fullName + (useUserStore().user.organisation ? ' - ' + useUserStore().user.organisation : '');
        });

        const userFullName = computed((): string => {
            return useUserStore().fullName;
        })

        const userId = computed((): string => {
            return useUserStore().userId;
        });

        const mailTo = (page: string, introText: string): string => {
            // Important!! Where it seem there are too many/too little spaces, this is intended. Do not remove these!
            const organisationSubjectPart = `${useUserStore().user.organisation ? ' - ' + useUserStore().user.organisation : ''}`
            const organisationBodyPart = `${useUserStore().organisationId ? '%0D%0A   - OrgId: ' + useUserStore().organisationId : ''}`
            const correlationIdPart = `${correlationId.value ?  '%0D%0A   - CorrelationId: '  + correlationId.value : ''}`;

            const intro = `${introText} %0D%0ATechnische informatie (verwijder dit deel van de mail niet, het technische team heeft deze nodig):`

            const infoLine = `Wil je meer info toevoegen, dan kan dat hieronder.%0D%0A-----------------------------------------------------`

            return `mailto:${helpdeskMail}?subject=${page}:${userFullName.value}${organisationSubjectPart}
                &body=${intro} %0D%0A ${correlationIdPart}%0D%0A   - UserId: ${userId.value} ${organisationBodyPart}
                %0D%0A%0D%0A${infoLine}`;
        }

        const returnToStart = () => {
            router.push('/');
        }

        const tryAgain = () => {
            router.go(0);
        }

        const logout = () => {
            useSignalrHub().stop(`${window.location.origin}/auth/logout`);
        }

        const copyToClipBoard = () => {
            navigator.clipboard.writeText(useErrorStore().lastCorrelationId);
        }

        const resetErrorBooleans = () =>{
            isError400Or405Or500.value = false;
            isError401.value = false;
            isError403.value = false;
            isError404.value = false;
        }

        const _checkErrorCode = () => {
            resetErrorBooleans();

            const errorCode = statusCode.value;
            if (useUserStore().user.roleError) {
                isRoleError.value = true;
            } else if (errorCode === '401') {
                isError401.value = true;
            } else if (errorCode === '403') {
                isError403.value = true;
            } else if (errorCode === '400' || errorCode === '405' || errorCode === '500') {
                isError400Or405Or500.value = true;
            } else {
                isError404.value = true;
            }
        }

        watch(() =>
            useErrorStore().lastStatusCode,
            () => {
                _checkErrorCode();
            },
            { immediate: false, deep: true })


        onMounted(() => {
            _checkErrorCode();
        });

        return {
            helpdeskMail,
            isError401,
            isError403,
            isError404,
            isError400Or405Or500,
            isRoleError,
            statusCode,
            correlationId,
            validationError,
            username,
            userFullName,
            userId,
            mailTo,
            returnToStart,
            tryAgain,
            logout,
            copyToClipBoard,
        }
    }
})
