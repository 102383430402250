import * as KlipApi from '@/api/klip-api.proxy';
import {klipApiProxy, useKlipApiProxy} from '@/plugins/proxy-client';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {isEmpty} from 'lodash';
import {CreateKlimZoneInput, CreateKlipZoneInput, UpdateKlimZoneInput, UpdateKlipZoneInput} from '@/api/klip-api.proxy';

export const useSettingsStore = defineStore('settings-store', () => {

    const _setting = ref<KlipApi.ISettingsView | null>(null);
    const _namespaceZone = ref<KlipApi.ZoneNamespace[] | null>([]);

    const _namespacesForOrganisationCache: Map<string, KlipApi.ZoneNamespace[]> = new Map<string, KlipApi.ZoneNamespace[]>();


    const settingsView = computed((): KlipApi.ISettingsView => {
        return _setting.value;
    });

    const namespaceZones = computed((): KlipApi.IZoneNamespace[] => {
        return _namespaceZone.value;
    });



    const fetchSettings = async (): Promise<void> => {
        const settings = await klipApiProxy.settings_GetSettingsView();
        _settingsViewLoaded(settings.result);
    }

    const loadNameSpaceZone = async (organisationId: string): Promise<void> => {
        if (_namespacesForOrganisationCache.has(organisationId)) {
            const namespaceZones = _namespacesForOrganisationCache.get(organisationId);
            _namespaceZoneLoaded(namespaceZones);
        } else {
            const zones = await klipApiProxy.organisation_LoadUnaZonesForOrganisation(organisationId, false);
            const namespaceZones = zones.result ? zones.result : [];
            _namespacesForOrganisationCache.set(organisationId, namespaceZones);
            _namespaceZoneLoaded(namespaceZones);
        }
    }

    const resetNamespaceZones = async (): Promise<void> => {
        _namespaceZoneLoaded([]);
    }

    const deleteNamespaceZone = async (deleteNamespace: { id: string, organisationId: string, unaId?: number }): Promise<void> => {
        await klipApiProxy.unaSettings_DeleteKlipZoneById(deleteNamespace.id, deleteNamespace.unaId);
        _namespaceZoneRemoved(deleteNamespace);
    }

    const _reloadNameSpaceZone = async (organisationId: string): Promise<void> => {
        _namespacesForOrganisationCache.delete(organisationId);
        return loadNameSpaceZone(organisationId);
    }

    const updateKlipNamespaceZone = async (data: UpdateKlipZoneInput): Promise<void> => {
        await klipApiProxy.unaSettings_UpdateKlipZone(data);
        _namespaceZoneUpdated({ id: data.id, organisationId: data.organisationId, data });
    }

    const createKlipNamespaceZone = async (data: CreateKlipZoneInput): Promise<void> => {
        const response = await klipApiProxy.unaSettings_CreateKlipZone(data);
        _namespaceZoneCreated({ id: response.result.id, organisationId: data.organisationId, data, isKlim: false, wkt: data.geometryWkt, activationDate: data.geometryActivationDate });
    }

    const updateKlimNamespaceZone = async (data: UpdateKlimZoneInput): Promise<void> => {
        // KLIM ZONES DO NOT HAVE GEOMETRIES! = no reload necessary
        await klipApiProxy.unaSettings_UpdateKlimZone(data);
        _namespaceZoneUpdated({ id: data.id, organisationId: data.organisationId, data });
    }

    const createKlimNamespaceZone = async (data: CreateKlimZoneInput): Promise<void> => {
        // KLIM ZONES DO NOT HAVE GEOMETRIES! = no reload necessary
        const response = await klipApiProxy.unaSettings_CreateKlimZone(data);
        _namespaceZoneCreated({ id: response.result.id, organisationId: data.organisationId, data, isKlim: true, wkt: null, activationDate: null });
    }

    const _settingsViewLoaded = (settings: KlipApi.ISettingsView): void => {
        _setting.value = settings;
    }

    const _namespaceZoneLoaded = (namespaces: KlipApi.ZoneNamespace[]) => {
        _namespaceZone.value = namespaces;
    }

    const _namespaceZoneRemoved = (deleteNamespace: { id: string, organisationId: string }) => {
        if (isEmpty(_namespaceZone.value)) {
            return;
        }
        _namespaceZone.value = _namespaceZone.value.filter((item) => item.zoneId !== deleteNamespace.id);
        _namespacesForOrganisationCache.set(deleteNamespace.organisationId, _namespaceZone.value);
    }

    // update from client input instead of server
    const _namespaceZoneUpdated = (update: { id: string, organisationId: string, data: KlipApi.IUpdateKlimZoneInput | KlipApi.IUpdateKlipZoneInput }) => {
        const index = _namespaceZone.value.findIndex((n) => n.zoneId === update.id);
        if (index > -1) {
            _namespaceZone.value[index].zoneName = update.data.name;
            _namespaceZone.value[index].namespace = update.data.namespace;
            _namespacesForOrganisationCache.set(update.organisationId, _namespaceZone.value);
        }
    }

    // update from client input instead of server
    const _namespaceZoneCreated = (create: { id: string, organisationId: string, data: KlipApi.ICreateKlimZoneInput | KlipApi.ICreateKlipZoneInput, isKlim: boolean, wkt: string, activationDate: string }) => {
        const newNamespaceZone: KlipApi.IZoneNamespace = {
            zoneId: create.id,
            zoneName: create.data.name,
            namespace: create.data.namespace,
            isKlimZone: create.isKlim,
            unaId: -1 ,
            isArchived: false,
            zoneGeometryActivationDate: create.activationDate,
            zoneGeometry: create.wkt,
        };
        _namespaceZone.value.push(KlipApi.ZoneNamespace.fromJS(newNamespaceZone));
        _namespacesForOrganisationCache.set(create.organisationId, _namespaceZone.value);
    }


    return {
        // GETTERS
        settingsView,
        namespaceZones,

        // ACTIONS
        fetchSettings,
        loadNameSpaceZone,
        resetNamespaceZones,
        deleteNamespaceZone,
        updateKlipNamespaceZone,
        createKlipNamespaceZone,
        updateKlimNamespaceZone,
        createKlimNamespaceZone,
    }
});
