import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Collection from 'ol/Collection';
import Draw from 'ol/interaction/Draw';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import MultiPoint from 'ol/geom/MultiPoint';
import { Vector as LayerVector } from 'ol/layer';
import { shiftKeyOnly, singleClick, pointerMove } from 'ol/events/condition';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {clone, isEmpty} from 'lodash-es';
import { Extent, createEmpty, extend } from "ol/extent";
import KlDrawZoneMapUtils from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map-utils';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import KlDrawZoneSidebar from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar.vue';
import { EDrawZoneMapState } from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import Vue, {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {RawLocation} from 'vue-router/types/router';
import {DrawZoneContext} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-context';
import {isEmpty as isEmptyExtent} from 'ol/extent';

export interface IDrawZoneOrgInfo {
    isKlim: boolean;
    guid: string;
    namespace: string;
    isActive: boolean;
    isArchived: boolean;
    willBeActivatedOn?: Date;
    editLink: RawLocation;
}

export interface IDrawZoneGeometryInfo {
    title?: string;
    isActive: boolean;
    subtitle: string;
    subtitle2?: string;
    version?: string | undefined;
    lastModificationDate?: string | undefined;
    precision?: string | undefined;
    downloadUrls?: { [key: string]: string; } | undefined;
}

export interface IDrawZoneMapGeometry {
    name?: string;
    coordinates?: number[][][]; // array of polygon[]; polygon = array of coordinates
    wkt?: string; // use coordinates OR wkt, NOT both
    isValid?: boolean;
    orgZoneInfo?: IDrawZoneOrgInfo;
    geometryInfo?: IDrawZoneGeometryInfo;
}

export default defineComponent({
    name: 'KlDrawZoneMap',
    components: { KlDrawZoneSidebar },
    emits: ['input', 'zone-price', 'state', 'remove-geometry', 'toggle-show-active'],
    props: {
        inputGeometries: { type: Array as () =>  IDrawZoneMapGeometry[], default: (): IDrawZoneMapGeometry[] => [], },
        modMaprequestZone: { type: Boolean, default: false, },
        modDisabled: { type: Boolean, default: false, },
        loadingMessage: { type: String, default: 'Zone wordt gevalideerd' },
        reference: { type: String, default: '', },
        rules: { type: [Object, String], default: '', },
        includeImkl: { type: Boolean, default: false, },
        forMaprequest: { type: Boolean, default: false, },
        noConfirmation: { type: Boolean, default: false, },
        modShowKmHmToggle: { type: Boolean, default: true, },
        modEnableMultiZones: { type: Boolean, default: false, },
        showMap: { type: Boolean, default: false, },

        forKlbZones: { type: Boolean, default: false, },
        canAddKlbZone: { type: Boolean, default: false, },
        canAddKlimZone: { type: Boolean, default: false, },
        addKlbZoneLink: { type: Object as () => RawLocation, default: null, },
        addKlimZoneLink: { type: Object as () => RawLocation, default: null, },
        showKlbActiveZoneToggle: { type: Boolean, default: false, },
        klbActiveZoneToggleValue: { type: Boolean, default: false, },

        forGeometry: { type: Boolean, default: false },
        showRemoveGeometry: { type: Boolean, default: false },
    },
    setup(props, {emit}) {

        const _context = new DrawZoneContext();

        const sidebar = ref<InstanceType<typeof KlDrawZoneSidebar>>(null);
        const map = ref(null);

        let _mapInstance: any = null;


        const isLoading = ref<boolean>(false);

        let kmhmLayer: ImageLayer = null;
        const kmhmLoadCounter = ref<number>(0);

        const kmhmEnabled = ref<boolean>(false);
        const kmhmHasError = ref<boolean>(false);

        const allowMultiPolygons = computed(() => {
            return props.forKlbZones || props.forGeometry;
        });

        // TODO: REVIEW
        const _enableSelectInteraction = computed((): boolean => {
           return !props.forGeometry;
        });
        const _enableModifyInteraction = computed((): boolean => {
           //return !props.forGeometry;
           return true;
        });


        const kmhmIsLoading = computed((): boolean => {
            return kmhmLoadCounter.value > 0;
        });

        let drawInteraction: Draw = null;
        let selectInteraction: Select = null;
        let modifyInteraction: Modify = null;

        watch(
            () => props.showMap,
            (showMap: boolean) => {
                if (showMap) {
                    Vue.nextTick(() => {
                        _mapInstance.updateSize();
                        if (_context.getCurrentFeature()) {
                            _getMap().zoomToFeature(_context.getCurrentFeature(), 1);
                        }
                    });
                }
            },
            {immediate: false, deep: false});

        // EXPERIMENT:
        // for klb-zones, the geometryInfo depends on other geometries in the zone.
        // = the info can change outside this component
        // BUT, this will cause problems when editing a Feature (infinite update loops)
        // watch(
        //     () => props.inputGeometries,
        //     () => {
        //             _getSidebar().initZones(_context, props.inputGeometries);
        //     },
        //     { immediate: false, deep: true }
        // )

        watch(
            kmhmEnabled,
            (checked: boolean) => {
                toggleKmHmLayer(checked);
            },
        )

        watch(
            _context.currentFeatureRef,
            (newFeature: Feature) => {
                const area = KlDrawZoneMapUtils.getArea(newFeature);
                _getSidebar().updateCurrentPolygonFeatureArea(area);
            },
            { immediate: false, deep: true })


        // INFO:
        // A better way would be to watch the props.inputGeometries, but this is to complicated in the current setup.
        // The geometries can be modified inside the map component, and can lead to infinite update loops.
        const updateInputGeometries = async (inputGeometries: IDrawZoneMapGeometry[]) => {
            // wait until component is properly initialized
            // initial 'inputGeometries' can be set in the props
            if (!_mapInstance) {
                return;
            }
            await _getSidebar().initZones(_context, inputGeometries);
        }

        const _createDrawInteraction = (): Draw => {
            return new Draw({
                type: 'Polygon',
                stopClick: true,
                style: [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(78, 79, 112, 0.8)',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: 'rgba(137, 138, 156, 0.4)',
                        }),
                    }),
                    new Style({
                        image: new Circle({
                            radius: 6,
                            fill: new Fill({
                                color: 'rgba(137, 138, 156, 0.4)',
                            }),
                            stroke: new Stroke({
                                color: 'rgba(78, 79, 112, 0.8)',
                                width: 1,
                            }),
                        }),
                    }),
                ],
                geometryFunction: (coordinates, geometry) => {
                    // Only used to show the 'opp=xx' while drawing a new polygon.
                    // can't find a proper openlayers way to listen to changes on the add-interaction

                    const newCoordinates = clone(coordinates[0]);
                    newCoordinates.push(coordinates[0][0]); // auto-close polygon

                    if (!geometry) {
                        geometry = new Polygon([newCoordinates]);
                    }
                    else {
                        geometry.setCoordinates([newCoordinates]);
                    }

                    _updateDrawingPolygon(geometry);
                    return geometry;
                }
            });
        }

        const _createSelectInteraction = (): Select => {
            let selectStyle: (f: Feature) => Style[] = null;

            if (props.forKlbZones) {
                selectStyle = (f: Feature) => [
                    new Style({
                        stroke: new Stroke({
                            color: f.orgZoneInfo?.isArchived ? 'rgba(34, 85, 89, 1)' : '#0055cc',
                            width: 3,
                            lineDash: (f.orgZoneInfo?.isActive || f.orgZoneInfo?.isArchived) ? undefined : [5, 3],
                        }),
                        fill: new Fill({
                            color: f.orgZoneInfo?.isArchived ? 'rgba(34, 85, 89, 0.85)' : 'rgba(0, 85, 204, 0.85)',
                        }),
                    })
                ];
            }
            else {
                selectStyle = (f: Feature) => [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(21, 155, 170, 0.8)',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: 'rgba(21, 155, 170, 0.4)',
                        }),
                    }),
                    new Style({
                        image: new Circle({
                            radius: 6,
                            fill: new Fill({
                                color: 'rgba(137, 138, 156, 0.4)',
                            }),
                            stroke: new Stroke({
                                color: 'rgba(78, 79, 112, 0.8)',
                                width: 1,
                            }),
                        }),
                        geometry: (feature) => {
                            // USE: put circles on the points (= only for styling)

                            if (!feature.getGeometry() || feature.getGeometry().flatCoordinates?.length < 8) {
                                return null;
                            }
                            // return the coordinates of the first ring of the polygon
                            const coordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];
                            return new MultiPoint(coordinates);
                        },
                    }),
                ];
            }

            let selectCondition = pointerMove;
            if (!props.forKlbZones) {
                selectCondition = (event) => {
                    if (shiftKeyOnly(event)) {
                        // Do NOT use this event for selecting/deselecting. Will also trigger while deleting points with 'shift' during modify.
                        return false;
                    }
                    return singleClick(event);
                };
            }

            return new Select({
                features: _context.selectedFeatures,
                condition: selectCondition,
                style: selectStyle,
            });
        }

        const _removeModifyInteraction = () => {
            if (modifyInteraction) {
                _mapInstance.removeInteraction(modifyInteraction);
            }
            modifyInteraction = null;
        }

        const _createModifyInteraction = (): Modify => {
            return new Modify({
                features: _context.selectedFeatures,
                deleteCondition: (event) => {
                    return shiftKeyOnly(event);
                },
            });
        }


        const _addKmHmLayer = () => {
            const source = new ImageWMS({
                url: 'https://opendata.apps.mow.vlaanderen.be/opendata-geoserver/awv/wms',
                params: {'LAYERS': 'Referentiepunten'},
            });

            source.on('imageloaderror', () => {
                kmhmLoadCounter.value--;
                kmhmHasError.value = true;
            });
            source.on('imageloadstart', () => {
                kmhmLoadCounter.value++;
                kmhmHasError.value = false;
            });
            source.on('imageloadend', () => {
                kmhmLoadCounter.value--;
                kmhmHasError.value = false;
            });

            kmhmLayer = new ImageLayer({
                source: source,
                visible: false,
            });

            _mapInstance.addLayer(kmhmLayer);
        }

        const _getSidebar = (): any => {
            return sidebar.value;
        }
        const _getMap = (): any => {
            return map.value;
        }

        const _addDrawingLayer = () => {
            let layerStyle: (f: Feature) => Style[] = null;

            if (props.forKlbZones || props.forGeometry) {
                layerStyle = (f: Feature) => {
                    return [new Style({
                    stroke: new Stroke({
                        color: f.orgZoneInfo?.isArchived ? 'rgba(34, 85, 89, 1)' : '#0055cc',
                        width: 2,
                        lineDash: f.orgZoneInfo?.isActive || f.orgZoneInfo?.isArchived || f.geometryInfo?.isActive ? undefined : [5, 3],
                    }),
                    fill: new Fill({
                        color: f.orgZoneInfo?.isArchived ? 'rgba(34, 85, 89, 0.6)' : 'rgba(0, 85, 204, 0.6)',
                    }),
                })]};
            }
            else {
                layerStyle = (f: Feature) => [new Style({
                    stroke: new Stroke({
                        color: 'rgba(78, 79, 112, 0.8)',
                        width: 1,
                    }),
                    fill: new Fill({
                        color: 'rgba(137, 138, 156, 0.4)',
                    }),
                })];
            }

            const drawLayer = new LayerVector({
                source: _context.source,
                style: layerStyle,
            });
            _mapInstance.addLayer(drawLayer);
        }

        const _removeDrawInteraction = () => {
            if (drawInteraction) {
                _mapInstance.removeInteraction(drawInteraction);
            }
            drawInteraction = null;
        }

        const _addDrawInteraction = () => {
            _removeDrawInteraction();
            drawInteraction = _createDrawInteraction();

            drawInteraction.setActive(true);
            _mapInstance.addInteraction(drawInteraction);

            drawInteraction.on('drawend', async (e) => {
                _context.selectFeature(e.feature);

                await _getSidebar().onDrawInteraction_DrawEnd(e.feature);
            });
        }

        const _removeSelectInteraction = () => {
            if (selectInteraction) {
                _mapInstance.removeInteraction(selectInteraction);
            }
            selectInteraction = null;
        }

        const _addSelectInteraction = () => {
            if (!_enableSelectInteraction.value) {
                return;
            }

            _removeSelectInteraction();
            selectInteraction = _createSelectInteraction();

            selectInteraction.setActive(true);
            _mapInstance.addInteraction(selectInteraction);

            selectInteraction.on('select', async (selectEvent: SelectEvent) => {
                await _getSidebar().onSelectInteraction_Select(selectEvent.selected, selectEvent.deselected);
            });
        }

        const _addModifyInteraction = () => {
            if (!_enableModifyInteraction.value) {
                return;
            }

            _removeModifyInteraction();
            modifyInteraction = _createModifyInteraction();

            modifyInteraction.setActive(true);
            _mapInstance.addInteraction(modifyInteraction);

            if (props.noConfirmation) {
                modifyInteraction.on('modifyend', async (event: ModifyEvent) => {
                    await _getSidebar().onModifyInteraction_ModifyEnd(event.features.item(0));
                });
            }
        }

        const _updateDrawingPolygon = (polygon: Polygon) => {
            const area = KlDrawZoneMapUtils.getPolygonArea(polygon);
            _getSidebar().updateCurrentPolygonFeatureArea(area);
        }
        const onStateChange = (state: EDrawZoneMapState) => {
            _setState(state);
        }

        const onZoomToFeature = (feature: Feature) => {
            if (feature && feature.getGeometry()) {
                _getMap().zoomToFeature(feature, 500);
            }
        }

        const onZoomToFeatures = (features: Collection<Feature>) => {
            if (isEmpty(features)) {
                return;
            }

            const extent: Extent = createEmpty();
            features.forEach((feature: Feature) => {
                if (feature.getGeometry() && feature.getGeometry().getExtent()) {
                    extend(extent, feature.getGeometry().getExtent())
                }
            });

            if (!isEmptyExtent(extent)) {
                _getMap().zoomToExtent(extent, 500);
            }
        }

        const onLoading = (loading: boolean) => {
            isLoading.value = loading;
        }

        const onInput = (newGeometries: IDrawZoneMapGeometry[]) => {
            emit('input', newGeometries);
        }

        const onZonePrice = (price: number) => {
            emit('zone-price', price);
        }

        const _setState = (state: EDrawZoneMapState) => {
            if (state === EDrawZoneMapState.overview) {
                _addSelectInteraction();

                _removeDrawInteraction();
                _removeModifyInteraction();

            } else if (state === EDrawZoneMapState.create) {
                _addDrawInteraction();

                _removeModifyInteraction();
                _removeSelectInteraction();

            } else if (state === EDrawZoneMapState.edit) {
                _addSelectInteraction();
                _addModifyInteraction();

                _removeDrawInteraction();

            } else if (state === EDrawZoneMapState.import) {
                _removeDrawInteraction();
                _removeModifyInteraction();
                _removeSelectInteraction();
            }

            emit('state', state);
        }

        const onSearchOnExtent = (extent: Extent) => {
            if (extent) {
                _getMap().zoomToExtent(extent, 500);
            }
        }

        const onSearchOnCoordinate = (coordinate: number[]) => {
            if (coordinate) {
                _getMap().zoomToCoordinates(coordinate, 500);
            }
        }

        const onRemoveGeometry = () => {
            emit('remove-geometry');
        }

        const onKlbToggleShowActive = (newValue: boolean) => {
            emit('toggle-show-active', newValue);
        }

        onMounted(() => {
            _context.clear();

            // map = getCurrentInstance().proxy.$refs['map'];
            // sidebar = getCurrentInstance().proxy.$refs['sidebar'];
            _mapInstance = _getMap().instance;

            _addKmHmLayer();
            _addDrawingLayer();

            _addKlbZonesSelectHandler();

            _getSidebar().initZones(_context, props.inputGeometries);
        })

        const _addKlbZonesSelectHandler = () => {
            if (!props.forKlbZones) {
                return;
            }

            _mapInstance.on('singleclick', async (iets: any) => {
                if (_context.selectedFeatures.getLength() === 1) {
                    _getSidebar().editKlbZone(_context.selectedFeatures.item(0).drawZoneId)
                }
            });
        }

        const toggleKmHmLayer = (checked: boolean) => {
            kmhmLayer?.setVisible(checked);
        }

        return {
            sidebar,
            map,

            allowMultiPolygons,

            isLoading,
            kmhmEnabled,
            kmhmHasError,
            kmhmIsLoading,

            updateInputGeometries,

            toggleKmHmLayer,

            onStateChange,
            onZoomToFeature,
            onZoomToFeatures,
            onLoading,
            onInput,
            onZonePrice,
            onSearchOnExtent,
            onSearchOnCoordinate,

            onKlbToggleShowActive,

            onRemoveGeometry,
        }
    }
})
