import {computed, defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {
    AskImpersonationConsentRequest,
    IAskImpersonationConsentRequest, ImpersonationStatus,
    UserImpersonationDto
} from "@/api/klip-api.proxy";
import {useUserStore} from "@/app/shared/state/UserDataModule";
import dayjs from "dayjs";

export default defineComponent({
    computed: {
        ImpersonationStatus() {
            return ImpersonationStatus
        }
    },
    props: {
        userId: {
            type: String,
            required: true
        },
        organisationId: {
            type: String,
            required: false
        },
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const startNewImpersonatingSession = ref<boolean>(false);
        const crmCaseNumber = ref<string>('');
        const activeImpersonation = ref<UserImpersonationDto>(null);
        const impersonationHistory = ref<Array<UserImpersonationDto>>([]);
        const loading = ref<boolean>(true);
        const startingImpersonation = ref<boolean>(false);
        const isSendingRequestForConsent = ref<boolean>(false);

        const formatDate = (timestamp: string) => {
            return dayjs(timestamp).utc().local().format('DD/MM/YYYY - HHumm')
        };

        const validUntil= (timestamp: string) => {
            return dayjs(timestamp).utc().local().add(1, 'hour').format('DD/MM/YYYY - HHumm')
        };

        const impersonationHistoryStatusText = (impersonationHistory: UserImpersonationDto) => {
            if (impersonationHistory.impersonationStatus === ImpersonationStatus.Revoked) {
                return `Aanvraag afgekeurd op ${formatDate(impersonationHistory.revokedAt)}`;
            } else {
                return `Toestemming verleend op ${formatDate(impersonationHistory.consentAt)}`;
            }
        };

        const startingNewImpersonatingSession = () => {
            startNewImpersonatingSession.value = !startNewImpersonatingSession.value;
        };

        const refresh = () => {
            window.location.reload();
        }

        const cancelStartNewImpersonatingSession = () => {
            startNewImpersonatingSession.value = !startNewImpersonatingSession.value;
        };

        const identityServerUrl = computed((): string => {
            return import.meta.env.VITE_IDENTITYSERVER_URI;
        });

        const startNewImpersonationSession = async () => {
            isSendingRequestForConsent.value = true;
            const request = new AskImpersonationConsentRequest({
                userId: props.userId,
                crmCaseNumber: crmCaseNumber.value,
                klipOrganisationId: props.organisationId,
                impersonatorUserId: useUserStore().userId
            } as IAskImpersonationConsentRequest);
            await proxy.users_AskImpersonationConsent(request);
            await loadUserImpersonations();
            isSendingRequestForConsent.value = false;
        };

        const startImpersonation = async () => {
            startingImpersonation.value = true;
            const startImpersonationResult = await proxy.users_StartImpersonationForUser(props.userId, activeImpersonation.value.id, useUserStore().userId);
            if (startImpersonationResult.isSuccessStatusCode) {
                activeImpersonation.value = startImpersonationResult.result;
            }
            startingImpersonation.value = false;
        }

        const cancelImpersonationSession = async () => {
            loading.value = true;
            await proxy.users_StopImpersonationForUser(props.userId, activeImpersonation.value.id);
            await loadUserImpersonations();
            loading.value = false;
        }

        const loadUserImpersonations = async () => {
            const impersonationResult = await proxy.users_ImpersonationForUser(props.userId, props.organisationId);
            if (impersonationResult.isSuccessStatusCode && impersonationResult.result) {
                activeImpersonation.value = impersonationResult.result.activeImpersonationSession;
                impersonationHistory.value = impersonationResult.result.history;
            }
        };

        const copyUserName = async () => {
            await navigator.clipboard.writeText(activeImpersonation.value.login.userName);
        };

        const copyPassword = async () => {
            await navigator.clipboard.writeText(activeImpersonation.value.login.password);
        };

        const copyImpersonationHyperlink = async () => {
            await navigator.clipboard.writeText(identityServerUrl.value);
        };

        onMounted(async () => {
            await loadUserImpersonations();
            loading.value = false;
        });

        return {
            formatDate,
            loading,
            refresh,
            isSendingRequestForConsent,
            startingImpersonation,
            startNewImpersonatingSession,
            cancelStartNewImpersonatingSession,
            cancelImpersonationSession,
            startingNewImpersonatingSession,
            startNewImpersonationSession,
            startImpersonation,
            activeImpersonation,
            impersonationHistory,
            crmCaseNumber,
            copyUserName,
            copyPassword,
            copyImpersonationHyperlink,
            identityServerUrl,
            validUntil,
            impersonationHistoryStatusText
        }
    }
});
