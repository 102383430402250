import Vue from 'vue';
import App from './app/app.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import router from './router/router';
import error from '@/app/error/error';

import webComponents from '@/plugins/webComponents';
import ipproComponents from '@/plugins/ipproComponents';
import globalComponents from '@/plugins/globalComponents';
import directives from '@/plugins/directives';
import VueProgressBar from 'vue-progressbar';

import SignalrHub from '@/plugins/signalr';
import routes from './plugins/routes';
import permission from './plugins/permission';
import alert from './plugins/alert';
import meta from './plugins/meta';
import proxyClient from './plugins/proxy-client';
import validations from './plugins/validations';
import filters from './plugins/filters';

import VueGtag from "vue-gtag"
import promiseFinally from 'promise.prototype.finally';
import { useUserStore } from './app/shared/state/UserDataModule';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"

dayjs.extend(utc);

promiseFinally.shim();

Vue.use(SignalrHub);
Vue.use(validations);
Vue.use(ipproComponents);
Vue.use(webComponents);
Vue.use(globalComponents);
Vue.use(directives);
Vue.use(permission);
Vue.use(alert);
Vue.use(meta);
Vue.use(filters);
Vue.use(proxyClient);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(routes, {
    router,
    error,
});
Vue.use(VueGtag, {
    config: {
        id: import.meta.env.VITE_GA4,
        params: {
            send_page_view: false, // no pageview on initial load!
            anonymize_ip: true,
            allow_google_signals: false, // disable all advertising features
            debug_mode: !!import.meta.env.VITE_GA4_DEBUG_MODE
        }
    },
}, router);

Vue.use(VueProgressBar, {
    color: 'rgb(0,0,0)',
    failedColor: 'red',
    height: '2px',
    transition: {
        speed: '0.2s',
        opacity: '0.0s',
        termination: 300,
    },
});

Vue.config.productionTip = false;

export default new Vue({
    router,
    pinia,
    render: (h) => h(App),
    created() {
        if (useUserStore().isCheckingAuthentication) {
            return;
        }
        this.$signalrHub.start();
    },
}).$mount('#app');
