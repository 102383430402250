import {computed, ComputedRef, defineComponent, ref, watch} from "vue";
import {debounce} from "lodash";
import Guid from "@/app/shared/helpers/guid";
import {KlSearchDeadLetterFilters} from "@/app/admin/dashboard/service-detail/components/kl-search-deadletter/search-deadletter";

export default defineComponent({
    props: {
        messageTypes: {
            type: Array<string>,
            default: [],
            required: true
        },
        debounceWait: {
            type: Number,
            default: 100
        }
    },
    emits: ['search'],
    setup(props, {emit}) {
        const searchField = ref<KlSearchDeadLetterFilters>(KlSearchDeadLetterFilters.CorrelationId);
        const deadLetterCorrelationId = ref<string>(null);
        const deadLetterMessageType = ref<string>(null);
        const deadLetterMessageTypes = ref<Array<string>>([]);
        const searchFields: ICodeListValueOrder[] = [
            <ICodeListValueOrder>{
                isDefaultValue: true,
                key: KlSearchDeadLetterFilters.CorrelationId,
                label: 'Correlation-id'
            },
            <ICodeListValueOrder>{
                key: KlSearchDeadLetterFilters.MessageType,
                label: 'Message type'
            }
        ];

        const watchFields: ComputedRef<[string, string]> = computed(() => {
            return [
                deadLetterCorrelationId.value,
                deadLetterMessageType.value
            ];
        });

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        }

        const messageTypeSelected = computed<boolean>(() => {
            return searchField.value === KlSearchDeadLetterFilters.MessageType;
        });

        const correlationIdSelected = computed<boolean>(() => {
            return searchField.value === KlSearchDeadLetterFilters.CorrelationId;
        });

        const debouncedSearch = debounce(() => {
            emit('search', {
                searchType: searchField.value,
                searchValue: correlationIdSelected.value ? deadLetterCorrelationId.value.replace(/-/gi, '') : deadLetterMessageType.value
            });
        }, props.debounceWait);

        const searchFieldChanged = () => {
            debouncedSearch();
        }

        const messageTypesChanged = (types: string[]) => {
            deadLetterMessageTypes.value = types;
        };

        watch(watchFields, searchFieldChanged, { immediate: false, deep: true });
        watch(() => props.messageTypes, messageTypesChanged, {immediate: true, deep: true});

        return {
            searchFields,
            searchFieldChanged,
            uniqueFieldId,
            searchField,
            deadLetterCorrelationId,
            deadLetterMessageType,
            messageTypeSelected,
            correlationIdSelected,
            deadLetterMessageTypes
        }
    }
});
