import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { EnvelopeOfString, EnvelopeOfDbGeometryText } from '@/api/klip-api.proxy';
import { IConfig } from '@/app/shared/components/kl-upload/config';
import {IGuideline} from '@/app/shared/components/kl-upload/guideline';
import {computed, defineComponent, ref} from 'vue';
import klUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import {componentRef} from '@/app/shared/helpers/vue-typescript-util';

export default defineComponent({
    name: 'KlUploadZone',
    emits: ['files-upload', 'upload-success'],
    components: {
        KlUpload,
    },
    props: {
        forMaprequest: { type: Boolean, required: false, default: false }, // TODO: refactor into decent props
        forGeometry: { type: Boolean, required: false, default: false }, // TODO: refactor into decent props

        useClientSideProcessing: { type: Boolean, required: false, default: false }, // TODO: refactor > only true is used!

        modHideGuideLines: { type: Boolean, required: false, default: false },
        maxFiles: { type: Number, required: false, default: 1 },
        moreInfoLink: { type: String, required: false, default: null },
    },
    setup(props, { emit }) {

        const zoneUpload = ref<InstanceType<typeof klUpload>>(null);

        const error = ref<boolean>(false);
        const errorMessages = ref<string[]>([]);


        const enableMultiFiles = computed((): boolean => {
            return props.maxFiles > 1;
        });

        const config = computed((): IConfig => {

            const guidelines: IGuideline[] = [];
            guidelines.push({
                label: 'Aanvaarde bestandsformaten',
                value: 'GeoJSON, Shapefile of WKT',
            });
            if (props.forMaprequest) {
                guidelines.push({
                    label: 'Maximale oppervlakte',
                    value: '100.000m²',
                });
            }
            guidelines.push({
                label: 'Maximale bestandsgrootte',
                value: props.forGeometry ? '100MB (als WKT, UTF-16 gecodeerd)' : '64kB (als WKT, UTF-16 gecodeerd)',
            });
            guidelines.push({
                label: 'Projectie',
                value: 'Lambert 72',
            });
            if (enableMultiFiles.value) {
                if (!props.forGeometry) {
                    guidelines.push({
                        value: 'Bevat geen multipolygonen',
                    });
                }
            }
            else {
                guidelines.push({
                    value: props.forGeometry ? 'Bevat maar 1 (multi)polygoon' : 'Bevat maar 1 polygoon',
                });
            }

            return {
                url:  import.meta.env.VITE_BASE_URI + '/bff/Mri/maprequest/zone/import',
                title: '',
                uploadLabel: enableMultiFiles.value ? 'Zone(s) toevoegen' : 'Zone toevoegen',
                uploadDragText: enableMultiFiles.value ? 'Sleep bestand(en) naar hier om toe te voegen.' : 'Sleep het bestand naar hier om toe te voegen.',
                guidelines: guidelines,

                // TODO: refactor > files config still used???
                files: {
                    amount: 1,
                    size: 0.064,
                    message: 'De grootte van het bestand mag maximaal 64kB zijn.',
                    types: '.json,.geojson,.shp,.shape,.wkt',
                },
            };
        });

        const filesAdded = (files: File[]) => {
            if (!props.useClientSideProcessing) {
                return;
            }

            _removeAllFiles();

            emit('files-upload', files);
        }

        const onProcessing = (e) => {
            if (!props.useClientSideProcessing) {
                return;
            }
            _removeAllFiles();
        }

        const _removeAllFiles = () => {
            componentRef<InstanceType<typeof klUpload>>(zoneUpload).value.removeAllFiles();
        }

        const onUploadSuccess = (file: object, response: EnvelopeOfDbGeometryText) => {
            if (!response.result) {
                return;
            }
            emit('upload-success', response.result.wkt);
        }

        const onUploadError = (file: object, message: EnvelopeOfString, xhr: object) => {
            if (xhr) {
                error.value = true;
                if (message && message.messages) {
                    errorMessages.value = errorMessages.value.concat(message.messages);
                }
            }
        }

        const reset = () => {
            error.value = false;
            errorMessages.value = [];
        }


        return {
            zoneUpload,

            error,
            errorMessages,
            enableMultiFiles,
            config,

            filesAdded,
            onProcessing,
            onUploadSuccess,
            onUploadError,
            reset,
        }
    }
})
