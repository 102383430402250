import {klipIndex} from '@/router/router';
import {EUserRole, useUserStore} from '@/app/shared/state/UserDataModule';
import {PaymentTypes} from '@/api/klip-api.proxy';
import {useShoppingCartStore} from '@/app/shared/state/ShoppingCartModule';
import {usePaymentStatusStore_toBeRemoved} from '@/app/shared/state/PaymentStatusModule';
import {mriShoppingCart} from '@/app/mri/mri-routes';
import {Prepaid} from '@/app/prepaid/prepaid-routes';
import {KlRouteConfig} from '@/router/router-vue';

export const Checkout: KlRouteConfig = {
    path: '/checkout',
    name: 'checkout',
    component: () => import('./checkout.vue'),
    meta: {
        authorize: {roles: [EUserRole.mri, EUserRole.mrr]},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
    beforeEnter: (to, from, next) => {
        if (useUserStore().user.paymentType !== PaymentTypes.Invoice) {
            if (useShoppingCartStore().hasInvalidItems) {
                next(mriShoppingCart);
            } else {
                next();
            }
        } else {
            next(false);
        }
    },
};

export const CheckoutPrepaid: KlRouteConfig = {
    path: '/checkoutprepaid',
    name: 'checkoutprepaid',
    component: () => import('./checkout.vue'),
    meta: {
        authorize: {roles: [EUserRole.mri, EUserRole.mrr]},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
    beforeEnter: (to, from, next) => {
        if (useUserStore().user.paymentType !== PaymentTypes.Invoice) {
            if (usePaymentStatusStore_toBeRemoved().hasPrepaidAmount) {
                next();
            } else {
                next(Prepaid);
            }
        } else {
            next(false);
        }
    },
};

export const ProcessingShoppingCart: KlRouteConfig = {
    path: '/processing/shoppingcart/:shoppingcartId',
    name: 'processingShoppingCart',
    component: () => import('./processing.vue'),
    meta: {
        authorize: {roles: [EUserRole.mri]},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
};

export const ProcessingTopUp: KlRouteConfig = {
    path: '/processing/topup/',
    name: 'processingTopUp',
    component: () => import('./processing.vue'),
    meta: {
        authorize: {roles: [EUserRole.mri]},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
};

export const Payment: KlRouteConfig = {
    path: '/payment',
    name: 'payment',
    component: () => import('./payment.vue'),
    meta: {
        authorize: {roles: [EUserRole.mri]},
        breadcrumb: {title: 'payment', parent: klipIndex},
    },
};

export default [
    Checkout,
    CheckoutPrepaid,
    Payment,
    ProcessingShoppingCart,
    ProcessingTopUp,
];
