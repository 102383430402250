import * as KlipApi from '@/api/klip-api.proxy';
import { useUserStore } from './UserDataModule';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';
import {CodeLists, MapRequestPrices} from '@/api/klip-api.proxy';

export const useCodeListStore = defineStore('codelist-store', () => {
    const codeLists: Ref<KlipApi.CodeLists> = ref(null);

    const holidays = computed((): string[] => {
        return codeLists.value ? codeLists.value.holidays : null;
    });

    const countryCodes = computed((): { [key: string]: string; } => {
        return codeLists.value ? codeLists.value.countryCodes : null;
    });

    const isBeta = computed((): boolean => {
        return codeLists.value && codeLists.value.applicationInfo?.environment === 'Beta';
    });

    const applicationInfo = computed((): KlipApi.IApplicationInfo => {
        return codeLists.value ? codeLists.value.applicationInfo : null;
    });

    const mapRequestPrices = computed((): MapRequestPrices => {
        return codeLists.value ? codeLists.value.mapRequestPrices : null;
    });

    const userRoleCodes = (role: string): string => {
        return codeLists.value ? codeLists.value.userRoleCodes[role] : role;
    }

    const updateCodeList = async () => {
        if (!codeLists.value && useUserStore().loggedOn) {
            return useKlipApiProxy().codeLists_GetAll()
                .then((data) => {
                    codeLists.value = data.result;
                });
        }
        return { codeLists };
    }

    return {
        holidays,
        countryCodes,
        isBeta,
        applicationInfo,
        mapRequestPrices,
        userRoleCodes,
        updateCodeList,

        codeLists, // expose state to allow $patch & initialState for mocking
    }
});
