import { AdditionalProcesses, IKlipApiProxy } from "@/api/klip-api.proxy";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import {AdminOrganisationDetail, AdminSettings} from "@/app/admin/admin-routes";
import {KlRouteConfig} from '@/router/router-vue';

export default defineComponent({
    setup() {
        const proxy = inject<IKlipApiProxy>('klipApiProxy');
        const additionalProcesses = ref<AdditionalProcesses>();

        const routeAdminSettings = computed((): KlRouteConfig => {
            return AdminSettings;
        });

        const routeAdminOrganisationDetail = computed((): KlRouteConfig => {
            return AdminOrganisationDetail;
        });

        onMounted(async () => {
            const additionalProcessesResult = await proxy.processes_AdditionalProcesses();
            if (additionalProcessesResult.isSuccessStatusCode) {
                additionalProcesses.value = additionalProcessesResult.result
            }
        });

        return {
            additionalProcesses,
            routeAdminSettings,
            routeAdminOrganisationDetail
        };
    }
});
