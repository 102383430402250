import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';
import { useCodeListStore } from '../../state/CodeListModule';
import {computed, defineComponent, ref, watch} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';

interface IBreadCrumb {
    label: string;
    route: KlRouteConfig;
}

export default defineComponent({
    name: 'KlBreadcrumb',
    props: {},
    setup(props) {

        const route = useRoute();
        const router = useRouter();

        // strange display problem when we start with an empty array ([]). ref: bug #180590
        const breadcrumbs = ref<IBreadCrumb[]>([{
            label: '',
            route: null,
        }]);


        const isBeta = computed(() => {
            return useCodeListStore().isBeta;
        });

        const to = (breadcrumb: IBreadCrumb) => {
            return routeName(breadcrumb) ? {
                name: routeName(breadcrumb),
                hash: breadcrumb.route && breadcrumb.route.hash ? breadcrumb.route.hash : '',
            } : null;
        }

        const routeName = (breadcrumb: IBreadCrumb) => {
            return (breadcrumb.route ? breadcrumb.route.name : '');
        }

        const _breadcrumbsChanged = () => {
            if (route.meta && route.meta.breadcrumb) {
                const newBreadcrumbs: IBreadCrumb[] = [];

                if (route.meta.breadcrumb.title || route.meta.title) {
                    newBreadcrumbs.push({
                        route: null, label: route.meta.breadcrumb.title ||
                            route.meta.title,
                    });
                }

                let parent = route.meta.breadcrumb.parent;
                let breakClause = false;
                while (parent && !breakClause) {
                    const parentRoute = router.resolve(parent);
                    if (
                        parentRoute.href !== '/'
                        && parentRoute.resolved.meta
                        && parentRoute.resolved.meta.breadcrumb
                        && (parentRoute.resolved.meta.breadcrumb.title || parentRoute.resolved.meta.title)
                    ) {
                        newBreadcrumbs.push({
                            route: parentRoute.resolved,
                            label: parentRoute.resolved.meta.breadcrumb.title || parentRoute.resolved.meta.title,
                        });
                        parent = parentRoute.route.meta.breadcrumb.parent;
                    } else {
                        breakClause = true;
                        const klipTitle = isBeta.value ? 'KLIP BETA' : 'KLIP';
                        newBreadcrumbs.push({ route: klipIndex, label: klipTitle });
                    }
                }

                const pageTitle = newBreadcrumbs.map((b) => b.label).join(' | ');

                // do not use meta, as it changes the route again
                // useMeta().setPageTitle(`${pageTitle} - Kabel- en Leidinginformatieplatform | Digitaal Vlaanderen`);

                // waarom moet een breadcrumb component dit doen?? Hoort dit niet bij de router, of de app?
                document.title = `${pageTitle} - Kabel- en Leidinginformatieplatform | Digitaal Vlaanderen`;

                breadcrumbs.value = newBreadcrumbs.reverse();
                //this.$set(this.breadcrumbs, 'breadcrumbs', newBreadcrumbs.reverse); // ???
            }
        }

        watch(
            () => route.meta.breadcrumb,
            _breadcrumbsChanged,
            { immediate: true, deep: true })


        return {
            breadcrumbs,
            isBeta,
            to,
            routeName,
        }
    }
})
