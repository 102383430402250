import userLocalStorage from '@/storage/user-local-storage';
import { UnaMapRequest } from '../../una-routes';
import {useUnaStore} from '../../una-store';
import * as KlipApi from '@/api/klip-api.proxy';
import * as UnaModel from '../../shared/una-model';
import KlSearchMaprequests, { MapRequestSearchField } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import { escape } from 'html-escaper';
import {computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';
import dayjs from "dayjs";

export default defineComponent({
    name: 'KlUnaToReply',
    emits: ['update-query'],
    props: {
        data: {
            type: Object as () => UnaModel.IMapRequestsToReply,
            required: true
        }
    },
    setup(props, { emit }) {

        const route = useRoute();
        const router = useRouter();
        const root = getCurrentInstance().proxy.$root;

        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);

        const mapRequests = computed((): UnaModel.IMapRequestListItem[] => useUnaStore().mapRequestsToReply);
        const mapRequestsToReplyTotalCount = computed((): number => useUnaStore().mapRequestsToReplyTotalCount);

        const _fetchMapRequestToReply = (input: KlipApi.GetMapRequestsToReplyInput) => useUnaStore().fetchMapRequestToReply(input);

        const searchOptionLocalStorageKey = 'una_search_to_reply_pa_organisation_id';

        const initialMappingFromQuery = ref<boolean>(true);
        const searchUsed = ref<boolean>(false);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsFetching = ref<boolean>(false);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsToReplyInput>(null);
        const previousSortedColumn = ref<KlipApi.IColumn>(null);
        const selectedZone = ref<string>('all');
        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());

        const mapRequestsMeta = reactive<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({
            totalRows: 0,
            resultsPerPage: 50,
            currentPage: 1
        });

        const defaultSearchOption = computed(() => {
            return !!props.data.defaultSearchOption ? props.data.defaultSearchOption : props.data && props.data.getMapRequestsInput && props.data.getMapRequestsInput.searchOption
                ? props.data.getMapRequestsInput.searchOption.toString() : null;
        });

        const _updateQuery = () => {
            if (route.hash === '#toreply') {
                emit('update-query', { input: mapRequestsInput.value, hash: '#toreply' });
            }
        }

        const pagerClicked = (page: number) => {
            mapRequestsFetching.value = true;
            mapRequestsMeta.currentPage = page;
            mapRequestsInput.value.offset = mapRequestsMeta.resultsPerPage * (page - 1);
        }

        const columnClicked = (column: KlipApi.IColumn) => {
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.orderField = KlipApi.MapRequestsToReplyOrderField[column.key[0].toUpperCase() + column.key.substr(1) as keyof typeof KlipApi.MapRequestsToReplyOrderField];

            if (previousSortedColumn.value && previousSortedColumn.value.key !== column.key) {
                column.direction = 1;
            }

            mapRequestsInput.value.orderDirection = column.direction;
            previousSortedColumn.value = column;
        }

        const rowClicked = (row: IRow) => {
            router.push({ name: UnaMapRequest.name, params: { id: row.mapRequestId, archived: 'false', zoneId: row.unaZoneId } });
        }

        const _parseCell = (row: IRow, column: IColumn) => {
            if (!row[column.key]) {
                return '';
            }

            const cellValue = escape(row[column.key])
            return {
                template: row.mapRequestId && row.unaZoneId
                    ? `<kl-router-link :to='${JSON.stringify(UnaMapRequest)}' :params='{id:"${row.mapRequestId}", archived:false, zoneId:"${row.unaZoneId}"}'>${cellValue}</kl-router-link>`
                    : `<span>${cellValue}</span>`,
            };
        }


        onMounted(() => {
            mapRequestsColumns.value = props.data.mapRequestColumns.map((column) => ({
                ...column,
                parser: _parseCell,
            }));
            defaultFilter.value = {
                searchField: props.data.getMapRequestsInput.searchField ? KlipApi.SearchField[props.data.getMapRequestsInput.searchField] : KlipApi.SearchField[KlipApi.SearchField.MyReference],
                searchOption: props.data.getMapRequestsInput.searchOption.toString(),
                organisationId: props.data.getMapRequestsInput.organisationId,
                keyword: props.data.getMapRequestsInput.query,
                date: {
                    from: props.data.getMapRequestsInput.startDate ? [props.data.getMapRequestsInput.startDate] : null,
                    to: props.data.getMapRequestsInput.endDate ? [props.data.getMapRequestsInput.endDate] : null,
                },
            } as MapRequestSearchField;
        });

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery.value) {
                searchUsed.value = true;
                mapRequestsFetching.value = true;
                mapRequestsInput.value = {
                    offset: 0,
                    limit: mapRequestsInput.value.limit,
                    orderDirection: mapRequestsInput.value.orderDirection,
                    orderField: mapRequestsInput.value.orderField,
                    query: searchFields.keyword,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    searchOption: isNaN(+searchFields.searchOption) ? KlipApi.SearchOption.SubOrganisation : +searchFields.searchOption as KlipApi.SearchOption,
                    organisationId: searchFields.organisationId,
                    utilityNetworkAuthorityZoneId: selectedZone.value === 'all' ? null : selectedZone.value,
                    startDate: searchFields.date.from && searchFields.date.from[0] ? dayjs(searchFields.date.from[0]).toISOString() : null,
                    endDate: searchFields.date.to && searchFields.date.to[0] ? dayjs(searchFields.date.to[0]).toISOString() : null,
                };
                mapRequestsMeta.currentPage = 1;
                _setSearchOptionToLocalStorage(searchFields);
                _updateQuery();
            }
            initialMappingFromQuery.value = false;
        }

        const clearSearch = () => {
            searchMaprequests.value.reset();
        }

        const _setSearchOptionToLocalStorage = (searchField: MapRequestSearchField) => {
            const isSubOrgSelected = isNaN(+searchField.searchOption);
            userLocalStorage.setItem(searchOptionLocalStorageKey, isSubOrgSelected ? searchField.organisationId : searchField.searchOption);
        }

        const _fetchMapRequests = async () => {
            mapRequestsFetching.value = true;
            mapRequestsColumns.value = (mapRequestsColumns.value || []).map((column) => ({
                ...column,
            }));
            const input = KlipApi.GetMapRequestsToReplyInput.fromJS(mapRequestsInput.value);
            await _fetchMapRequestToReply(input);
            mapRequestsFetching.value = false;
        }


        watch(
            () => props.data,
            (newData: UnaModel.IMapRequestsToReply) => {
                if (newData) {
                    mapRequestsInput.value = newData.getMapRequestsInput;
                    mapRequestsMeta.currentPage = Math.ceil(mapRequestsInput.value.offset / mapRequestsMeta.resultsPerPage) + 1;
                }
            },
            { immediate: true, deep: true })

        watch(
            mapRequestsInput,
            (newMapRequestsInput: KlipApi.IGetMapRequestsToReplyInput) => {
                _updateQuery();
                if (newMapRequestsInput) {
                    _fetchMapRequests();
                }
            },
            { immediate: true, deep: true });

        watch(
            selectedZone,
            (newZoneId: string) => {
                if(!newZoneId){
                    return;
                }
                mapRequestsInput.value.utilityNetworkAuthorityZoneId = newZoneId === 'all' ? null : newZoneId;
                mapRequestsInput.value.offset = 0;
                mapRequestsInput.value.limit = mapRequestsMeta.resultsPerPage;
                mapRequestsMeta.currentPage = 1;
                _updateQuery();
            },
            { immediate: true, deep: true });

        // watch(
        //     searchOption,
        //     (newSearchOption: string) => {
        //         mapRequestsInput.value.searchOption = KlipApi.SearchOption[newSearchOption as keyof typeof KlipApi.SearchOption];
        //     },
        //     { immediate: false, deep: false });

        watch(
            mapRequestsToReplyTotalCount,
            (newCount) => {
                mapRequestsMeta.totalRows = newCount;
            },
            { immediate: true, deep: true });

        return {
            searchMaprequests,

            mapRequests,
            mapRequestsToReplyTotalCount,
            searchOptionLocalStorageKey,
            initialMappingFromQuery,
            searchUsed,
            mapRequestsMeta,
            mapRequestsColumns,
            mapRequestsFetching,
            mapRequestsInput,
            previousSortedColumn,
            selectedZone,
            defaultFilter,
            defaultSearchOption,

            pagerClicked,
            columnClicked,
            rowClicked,
            search,
            clearSearch,
        };

    }
})
