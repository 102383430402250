﻿import moment from "moment";
import {Route} from "vue-router";

export default class QueryFilter {
    mapFilterFromQuery(route: Route, object: any, defaultValues?: any, typeDefinition?: any) {
        object = this.addDefaultValues(object, defaultValues);
        const newObject: any = {};
        try {
            if (route.query && object) {
                const query = route.query;
                Object.keys(query).forEach((key) => {
                    if (key in object) {
                        if (key.toLowerCase().includes('zone')) {
                            newObject[key] = (query[key] as string).replace(/\\/g, '');
                        } else if (moment(query[key], 'DD-MM-YYYY', true).isValid()) {
                            newObject[key] = moment(query[key], 'DD-MM-YYYY', true).format('YYYY-MM-DD HH:mm:ss');
                        } else if (Array.isArray(newObject[key])) {
                            newObject[key] = (query[key] as string).split(',');
                        } else if (/^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/g.exec(query[key] as string)) {
                            newObject[key] = parseInt(query[key] as string, 10);
                        } else if (typeDefinition && typeDefinition[key] === 'boolean') {
                            newObject[key] = (query[key] as string)?.toLowerCase() === 'true';
                        } else {
                            newObject[key] = query[key];
                        }
                    }
                });
            }
            // type validation
            if (typeDefinition) {
                Object.keys(typeDefinition).forEach((key) => {
                    if (newObject[key]) {
                        if (typeof typeDefinition[key] === 'object') {
                            if (!typeDefinition[key][newObject[key]]) {
                                throw new Error('query param invalid range');
                            }
                        } else if (typeDefinition[key] === 'boolean') {
                            if (typeof newObject[key] !== 'boolean') {
                                throw new Error('query param invalid boolean');
                            }
                        } else if (typeDefinition[key] === 'Date') {
                            if (!moment(newObject[key], 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
                                throw new Error('query param invalid Date');
                            }
                        }
                    }
                });
            }
        } catch (e) {
            this.removeQuery(route);
            return object;
        }
        Object.keys(newObject).forEach((key) => {
            object[key] = newObject[key];
        });
        return object;
    }

    addDefaultValues(object: any, defaultValues?: any) {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                object[key] = defaultValues[key];
            });
        }
        return object;
    }

    mapColumnsFromQuery(route: Route, columns: any[], type: any) {
        if (route.query) {
            const query = route.query;
            const keyName = type[parseInt(query['orderField'] as string, 10)];
            if (query['orderField'] && keyName) {
                columns.forEach((column) => {
                    if (column.key.toLowerCase() === keyName.toLowerCase() && query['orderDirection']) {
                        const direction = parseInt(query['orderDirection'] as string, 10);
                        if (direction <= 2) {
                            column.direction = direction;
                        }
                    }
                });
            }
        }
    }

    getQuery(object: any) {
        return Object.keys(object)
            .map((key, index, array) => {
                if (object[key] && !['limit', 'zoneId'].includes(key) && !(Array.isArray(object[key]) && object[key].length === 0)) {
                    if (moment(object[key], 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(moment(object[key], 'YYYY-MM-DD HH:mm:ss', true).format('DD-MM-YYYY'));
                    } else {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
                    }
                }
            }).filter((value) => {
                if (value) {
                    return value;
                }
            })
            .join('&');
    }

    mapQuery(route: Route, object: any) {
        const query = this.getQuery(object);
        history.pushState(
            {},
            null,
            `${route.path}?${query}${route.hash}`,
        );
    }

    setQuery(route: Route, query: string) {
        history.pushState(
            {},
            null,
            route.path +
            query ? '?' + query : ''
                + route.hash,
        );
    }

    removeQuery(route: Route) {
        history.pushState(
            {},
            null,
            route.path + route.hash,
        );
    }
}
