import { useCodeListStore } from '@/app/shared/state/CodeListModule';
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlTestPaymentData',
    props: {},
    setup(props) {

        const isVisible = computed((): boolean => {
            return useCodeListStore().applicationInfo && (useCodeListStore().applicationInfo.environment === 'Test' || useCodeListStore().applicationInfo.environment === 'Beta');
        });

        return {
            isVisible,
        };
    }
})
