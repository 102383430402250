import MD5 from 'md5';
import {useUserStore} from '@/app/shared/state/UserDataModule';

export default abstract class UserLocalStorage {

    public static getItem(key: string, isObject: boolean = false) {
        const localStorageHash = this.getHashedUserId();
        const value = localStorage.getItem(`${key}_${localStorageHash}`);
        if (!isObject) {
            return value;
        }

        try {
            return JSON.parse(value);
        }
        catch(e) {
            // return null when there is a conversion error
            return null;
        }
    }

    public static setItem(key: string, value: any, isObject: boolean = false): void {
        let serialized = value;
        if (isObject) {
            serialized = JSON.stringify(value);
        }
        const localStorageHash = this.getHashedUserId();
        localStorage.setItem(`${key}_${localStorageHash}`, serialized);
    }

    public static appendItemToArray(key: string, value: any): void {
        let storedValue: string[] = this.getItem(key, true) as [];
        if (!storedValue) {
            storedValue = [];
        }
        storedValue.push(value);

        const serialized = JSON.stringify(storedValue);
        localStorage.setItem(`${key}_${this.getHashedUserId()}`, serialized);
    }

    public static removeItem(key: string): void {
        const localStorageHash = this.getHashedUserId();
        localStorage.removeItem(`${key}_${localStorageHash}`);
    }

    private static getHashedUserId(): string {
        const userId = useUserStore().userId;
        const organisationId = useUserStore().organisationId;
        if (userId && organisationId) {
            return MD5(`${userId}-${organisationId}`);
        }
        if (userId) {
            return MD5(`${userId}`);

        }
        return '';
    }
}
