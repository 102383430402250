import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {CreateMapRequestType} from '@/api/klip-api.proxy';
import {useUserStore} from '@/app/shared/state/UserDataModule';

export default defineComponent({
    name: 'KlMriCreateHeading',
    emits: ['go-to-step'],
    props: {
        createMapRequestType: {
            type: Number, //CreateMapRequestType,
            required: false,
            default: null,
        },
        activeStep: {
            type: Number,
            required: true,
        },
        viewModelLoading: {
            type: Boolean,
            required: true,
        },
        steps: {
            type: Array,
            required: true,
        }
    },
    setup(props, { emit }) {

        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;

        const title = computed(() => {
            return props.createMapRequestType === CreateMapRequestType.EditShoppingCart ? 'Planaanvraag aanpassen' : 'Nieuwe planaanvraag';
        });

        const isInfoAlertVisible = computed((): boolean => {
            return useUserStore().user.isCitizen;
        });

        const goToStep = (step: number) => {
            emit('go-to-step', step);
        }


        return {
            title,
            isInfoAlertVisible,
            helpdeskMail,
            goToStep,
        }
    }
})
