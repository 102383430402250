import { organisationSettings } from '../../settings-routes';
import KlEditSubOrganisation from './kl-edit-suborganisation/kl-edit-suborganisation.vue';
import KlCreateSubOrganisation from './kl-create-suborganisation/kl-create-suborganisation.vue';
import KlUnaZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-zone/kl-una-zone.vue';
import KlUnaCreateEditZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-create-edit-zone/kl-una-create-edit-zone.vue';
import KlUnaCreateEditKlimZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-create-edit-klimzone/kl-una-create-edit-klimzone.vue';
import {KlRouteConfig} from '@/router/router-vue';
import {EUserRole} from '@/app/shared/state/UserDataModule';

export const NewSubOrganisation: KlRouteConfig = {
    path: '/settings/organisation/create',
    name: 'newsuborganisation',
    component: KlCreateSubOrganisation,
    meta: {
        authorize: { roles: [EUserRole.org_manager] },
        breadcrumb: { title: 'Nieuwe suborganisatie', parent: organisationSettings },
    },
};

export const EditSubOrganisation: KlRouteConfig = {
    path: '/settings/organisation/:organisationId',
    props: true,
    name: 'editsuborganisation',
    component: KlEditSubOrganisation,
    meta: {
        authorize: { roles: [EUserRole.org_manager, EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin, EUserRole.mri] },
        breadcrumb: { title: 'Bewerk suborganisatie', parent: organisationSettings },
    },
};

export const UnaZone: KlRouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/detail/:zoneId',
    props: true,
    name: 'unazone_readonly',
    component: KlUnaZone,
    meta: {
        authorize: { roles: [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin] },
        breadcrumb: { title: 'Zone detail', parent: EditSubOrganisation },
    },
};

export const NewUnaZone: KlRouteConfig = {
    path: `/settings/organisation/:organisationId/unazone/create`,
    props: true,
    name: 'newunazone',
    component: KlUnaCreateEditZone,
    meta: {
        authorize: { roles: [EUserRole.una_manager] },
        breadcrumb: { title: 'Nieuwe zone', parent: EditSubOrganisation },
    },
};

export const EditUnaZone: KlRouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/:zoneId/edit',
    props: true,
    name: 'editunazone',
    component: KlUnaCreateEditZone,
    meta: {
        authorize: { roles: [EUserRole.una_manager] },
        breadcrumb: { title: 'Bewerk zone', parent: EditSubOrganisation },
    },
};

export const UnaKlimZone: KlRouteConfig = {
    path: '/settings/organisation/:organisationId/unazoneklim/:zoneId',
    props: true,
    name: 'unaklimzone_readonly',
    component: KlUnaZone,
    meta: {
        authorize: { roles: [EUserRole.una_manager, EUserRole.una_operator, EUserRole.una_reader, EUserRole.klim_admin] },
        breadcrumb: { title: 'Zone detail', parent: EditSubOrganisation },
    },
};

export const NewUnaKlimZone: KlRouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/create',
    props: true,
    name: 'newunaklimzone',
    component: KlUnaCreateEditKlimZone,
    meta: {
        authorize: { roles: [EUserRole.klim_admin] },
        breadcrumb: { title: 'Nieuwe KLIM zone', parent: EditSubOrganisation },
    },
};

export const EditUnaKlimZone: KlRouteConfig = {
    path: '/settings/organisation/:organisationId/unazoneklim/:zoneId/edit',
    props: true,
    name: 'editunaklimzone',
    component: KlUnaCreateEditKlimZone,
    meta: {
        authorize: { roles: [EUserRole.klim_admin] },
        breadcrumb: { title: 'Bewerk KLIM zone', parent: EditSubOrganisation },
    },
};


export default [NewSubOrganisation, EditSubOrganisation, UnaZone, NewUnaZone, EditUnaZone, UnaKlimZone, NewUnaKlimZone, EditUnaKlimZone];

