import {computed, defineComponent, inject, onMounted, onUnmounted, ref, watch} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {CurrentInvoiceRound, IKlipApiProxy, InvoiceStates, LogTypes, MapRequestWithFinancialAnomalies} from "@/api/klip-api.proxy";
import { ISelectedOrganisation } from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import debounce from "lodash-es/debounce";
import Guid from "@/app/shared/helpers/guid";
import dayjs from "dayjs";
import {AdminMapRequestWithFinancialAnomalies, AdminSettings} from "@/app/admin/admin-routes";
import {KlRouteConfig} from '@/router/router-vue';

export default defineComponent({
    setup() {
        const dateFormat = 'DD/MM/YYYY';
        const yesterday = dayjs().add(-1, "day").format(dateFormat);

        let invoiceRoundintervalId: NodeJS.Timer = null;
        const roundSuccessfullyDone = ref<boolean>(false);
        const startingNewRound = ref<boolean>(false);
        const klipApiProxy = inject<IKlipApiProxy>('klipApiProxy');
        const isSending = ref<boolean>(false);
        const organisationDataFetching = ref<boolean>(false);
        const selectedOrganisationTpe = ref<string>('all');
        const dateFrom = ref<string[]>([yesterday]);
        const dateFromMaxDate = ref<string>(yesterday);
        const selectedOrganisations = ref<ISelectedOrganisation[]>([]);
        const organisationSuggestion = ref<ISelectedOrganisation[]>([]);
        const currentInvoiceRound = ref<KlipApi.CurrentInvoiceRound>(null);
        const currentInvoiceRoundId = ref<string>('');
        const loading = ref<boolean>(true);
        const error = ref<string>('');
        const nothingToInvoice = ref<boolean>(false);
        const mapRequestsNotRegisteredInFinancialTransaction = ref<MapRequestWithFinancialAnomalies[]>([]);
        const organisationSelection = computed((): boolean => {
            return selectedOrganisationTpe.value === 'select';
        });

        const routeAdminMapRequestWithFinancialAnomalies = computed((): KlRouteConfig => {
            return AdminMapRequestWithFinancialAnomalies;
        });

        const debouncedOrganisationSearch = debounce((searchValue) => {
            if (!searchValue) {
                return;
            }

            organisationDataFetching.value = true;
            klipApiProxy.organisation_FilterOrganisationsByName(searchValue, false, true)
                .then((filterResult) => {
                    organisationSuggestion.value = filterResult.result.map((item) => {
                        return {
                            organisationId: item.organisationId,
                            name: item.name,
                            parentId: item.parentId,
                            namePath: item.namePath
                        }

                    });
                })
                .finally(() => {
                    organisationDataFetching.value = false;
                });
        }, 200);

        const onOrganisationInputChange = (searchQuery: string, id) => {
            debouncedOrganisationSearch(searchQuery);
        };

        const title = computed(() => {
            if (noActiveRoundRunning.value) {
                return 'Start Facturatieronde';
            } else {
                return `Facturatieronde van ${formatDate(currentInvoiceRound.value.startTime)} - ${currentInvoiceRoundId.value}`
            }
        });

        const pollInvoiceRound = async () => {
            const response = await klipApiProxy.invoice_CurrentInvoiceRound();
            if (response.isSuccessStatusCode && response.result !== null) {
                if (response.result.state === InvoiceStates.InvoicesSent ||
                    response.result.state === InvoiceStates.Completed) {
                    roundSuccessfullyDone.value = true;
                }
                currentInvoiceRound.value = response.result;
            }
        };

        const noActiveRoundRunning = computed(() => {
            return (currentInvoiceRound.value === null || currentInvoiceRound.value === undefined) ||
                currentInvoiceRound.value.state === InvoiceStates.InvoicesSent || currentInvoiceRound.value.state === InvoiceStates.Completed ||
                startingNewRound.value;
        });

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        };

        const formatDate = (timestamp: string) => {
            return dayjs(timestamp).format('DD/MM/YYYY - HHumm');
        };

        const startNewRound = () => {
            const invoiceRound = new CurrentInvoiceRound();
            invoiceRound.incorrectVatNumbers = [];
            invoiceRound.log = [];
            currentInvoiceRound.value = new CurrentInvoiceRound();
            startingNewRound.value = true;
        };

        const onFormSubmit = async () => {
            startingNewRound.value = true;
            roundSuccessfullyDone.value = false;

            error.value = '';

            const invoiceRoundData = {
                startDate: dayjs(dateFrom.value[0]).toISOString(),
                organisations: selectedOrganisations.value.map(s => s.organisationId)
            } as KlipApi.StartInvoicingRoundRequest

            const startInvoiceRoundResult = await klipApiProxy.invoice_StartInvoiceRound(invoiceRoundData);

            if (startInvoiceRoundResult.result && startInvoiceRoundResult.result.success) {
                if (!!startInvoiceRoundResult.result.invoiceRoundId) {
                    currentInvoiceRoundId.value = startInvoiceRoundResult.result.invoiceRoundId;
                    currentInvoiceRound.value = new CurrentInvoiceRound();
                    startingNewRound.value = false;
                    invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
                } else {
                    nothingToInvoice.value = true;
                }
            } else {
                error.value = startInvoiceRoundResult.result.failure.errors[0].message;
            }

            startingNewRound.value = false;
        };

        const logClass = (log: KlipApi.InvoicingLogMessage) => {
            return [
                'kl-invoice-alert', {
                    'kl-invoice-alert--error': log.logTypes === LogTypes.Error,
                    'kl-invoice-alert--warning': log.logTypes === LogTypes.Warning,
                    'kl-invoice-alert--success': log.logTypes === LogTypes.Info,
                }];
        }

        const onDateFromChanged = async (d) => {
            const result = await klipApiProxy.invoice_MapRequestsNotRegisteredInFinancialTransaction(dayjs(d[0]).format('YYYY/MM/DD'));
            mapRequestsNotRegisteredInFinancialTransaction.value = result.result;
        };

        onMounted(async () => {
            const currentRoundResult = await klipApiProxy.invoice_CurrentInvoiceRound();

            if (currentRoundResult.isSuccessStatusCode) {
                currentInvoiceRound.value = currentRoundResult.result;

                // support old and new flow
                if (currentInvoiceRound.value.state !== InvoiceStates.InvoicesSent &&
                    currentInvoiceRound.value.state !== InvoiceStates.Completed) {
                    invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
                } else {
                    roundSuccessfullyDone.value = true;
                }
            }

            loading.value = false;
        });

        onUnmounted(() => {
            clearInterval(invoiceRoundintervalId);
        });

        watch(dateFrom, onDateFromChanged);

        return {
            isSending,
            dateFrom,
            formatDate,
            dateFromMaxDate,
            organisationSuggestion,
            selectedOrganisationTpe,
            selectedOrganisations,
            organisationSelection,
            organisationDataFetching,
            currentInvoiceRound,
            currentInvoiceRoundId,
            noActiveRoundRunning,
            roundSuccessfullyDone,
            startingNewRound,
            title,
            loading,
            mapRequestsNotRegisteredInFinancialTransaction,
            routeAdminMapRequestWithFinancialAnomalies,
            logClass,
            uniqueFieldId,
            onOrganisationInputChange,
            onFormSubmit,
            nothingToInvoice,
            error
        };
    }
});
