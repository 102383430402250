import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import QueryFilter from '@/app/shared/helpers/queryFilter';
import { useRoute, useRouter } from '@/plugins/routes';
import KlSearchMaprequests, { MapRequestSearchField } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import { escape } from 'html-escaper';
import { KlRouteConfig } from "@/router/router-vue";
import DateUtil from '@/app/shared/helpers/date-util';
import {handleAbortError, KlAbortController} from '@/app/shared/helpers/kl-abort-controller';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import dayjs from "dayjs";

export default defineComponent({
    name: 'KlPdaMaprequests',
    props: {
        zoneId: {
            type: String,
            default: null,
            required: true
        },
        zoneDetailView: {
            type: KlipApi.ZoneDetailView,
            default: null,
            required: true
        },
        parentOrganisationId: {
            type: String,
            default: null,
            required: true
        },
        maprequestDetailRoute: {
            type: Object as () => KlRouteConfig,
            default: null,
            required: true
        }
    },
    setup: function (props) {

        const _pda_GetMapRequests_AbortController = new KlAbortController();
        const _pda_GetMapRequestsCount_AbortController = new KlAbortController();

        const route = useRoute();
        const router = useRouter();
        const proxy = useKlipApiProxy();
        const queryFilter = new QueryFilter();

        const initialMappingFromQuery = ref<boolean>(true);
        const mapRequests = ref<KlipApi.IMapRequestListItem2[]>([]);
        const mapRequestsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });
        const mapRequestsFetching = ref<boolean>(true);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsInput3>(null);
        const mapRequestsSearchFields = ref<KlipApi.CodeListValueOrder[]>([]);
        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());

        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);

        const routePdaMapRequest = computed((): KlRouteConfig => {
            return props.maprequestDetailRoute;
        });

        const queryTypeDefinition = {
            orderField: KlipApi.OrderField,
            orderDirection: KlipApi.OrderDirection,
            searchField: KlipApi.SearchField,
            startDate: 'Date',
            endDate: 'Date'
        };

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery.value) {
                mapRequestsFetching.value = true;
                mapRequestsInput.value = {
                    zoneId: props.zoneId,
                    parentOrganisationId: props.parentOrganisationId,
                    offset: 0,
                    limit: mapRequestsInput.value.limit,
                    orderDirection: mapRequestsInput.value.orderDirection,
                    orderField: mapRequestsInput.value.orderField,
                    query: searchFields.keyword,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    startDate: searchFields.date.from && searchFields.date.from[0] ? dayjs(searchFields.date.from[0]).toISOString() : null,
                    endDate: searchFields.date.to && searchFields.date.to[0] ? dayjs(searchFields.date.to[0]).toISOString() : null,
                };

                queryFilter.mapQuery(route, mapRequestsInput.value);
            }

            initialMappingFromQuery.value = false;
        }

        const _fetchMapRequestsCount = async () => {
            try {
                const input = KlipApi.GetMapRequestsInput3.fromJS(mapRequestsInput.value);
                const response = await proxy.pda_GetMapRequestsCount(input, _pda_GetMapRequestsCount_AbortController.abortAndCreateNewSignal());

                mapRequestsMeta.value.totalRows = response.result;
            }
            catch (err) {
                handleAbortError(err);
            }
        }

        const _fetchMapRequests = async () => {
            try {
                const input = KlipApi.GetMapRequestsInput3.fromJS(mapRequestsInput.value);
                const response = await proxy.pda_GetMapRequests(input, _pda_GetMapRequests_AbortController.abortAndCreateNewSignal());

                if (!response.result) { return; }

                mapRequests.value = response.result.map((mapRequest) => ({
                    id: mapRequest.mapRequestId,
                    reference: mapRequest.reference,
                    requestor: mapRequest.requestor,
                    dateReceived: DateUtil.formatDate(mapRequest.dateReceived),
                }));

                mapRequestsFetching.value = false;
            }
            catch (err) {
                handleAbortError(err);
            }
        }

        const pagerClicked = (page: number) => {
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = mapRequestsMeta.value.resultsPerPage * (page - 1);
            queryFilter.mapQuery(route, mapRequestsInput.value);
        }

        const columnClicked = (column: KlipApi.Column) => {
            mapRequestsFetching.value = true;
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.orderField = KlipApi.OrderField2[column.key[0].toUpperCase() + column.key.substring(1) as keyof typeof KlipApi.OrderField2];
            mapRequestsInput.value.orderDirection = column.direction;
            queryFilter.mapQuery(route, mapRequestsInput.value);
        }

        const clearSearch = () => {
            searchMaprequests.value.reset();
        }

        const onMapRequestsInputChanged = (mapRequestInput: KlipApi.IGetMapRequestsInput3) => {
            if (mapRequestInput) {
                _fetchMapRequestsCount();
                _fetchMapRequests();
            }
        }

        const parseCell = (row: IRow, column: KlipApi.IColumn): { template: string } => {
            const cellValue = escape(row[column.key]);
            if (row.id) {
                const routeParams = {
                    id: row.id,
                    zoneName: props.zoneDetailView.zoneDetails.name
                }
                return { template: `<kl-router-link :to='${JSON.stringify(routePdaMapRequest.value)}' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>` };
            } else {
                return { template: `<div>${cellValue}</div>` };
            }
        }

        watch(mapRequestsInput, onMapRequestsInputChanged, { immediate: true, deep: true });

        onMounted(async () => {
            mapRequestsInput.value = queryFilter.mapFilterFromQuery(route, props.zoneDetailView.getMapRequestsInput, { searchField: 2 }, queryTypeDefinition);
            mapRequestsInput.value.parentOrganisationId = props.parentOrganisationId;
            mapRequestsMeta.value.currentPage = Math.ceil(mapRequestsInput.value.offset / mapRequestsMeta.value.resultsPerPage) + 1;
            mapRequestsColumns.value = props.zoneDetailView.mapRequestColumns.map((column) => ({
                ...column,
                parser: parseCell,
            }));

            queryFilter.mapColumnsFromQuery(route, mapRequestsColumns.value, KlipApi.OrderField2);
            mapRequestsSearchFields.value = props.zoneDetailView.searchFields;

            defaultFilter.value = {
                searchField: mapRequestsInput.value.searchField ? KlipApi.SearchField[mapRequestsInput.value.searchField] : null,
                keyword: mapRequestsInput.value.query,
                date: {
                    from: mapRequestsInput.value.startDate ? [mapRequestsInput.value.startDate] : null,
                    to: mapRequestsInput.value.endDate ? [mapRequestsInput.value.endDate] : null,
                },
            } as MapRequestSearchField;
        });

        return {
            searchMaprequests,
            search,
            defaultFilter,
            mapRequests,
            mapRequestsSearchFields,
            mapRequestsMeta,
            mapRequestsColumns,
            mapRequestsFetching,
            routePdaMapRequest,
            pagerClicked,
            columnClicked,
            clearSearch
        };
    }
})
